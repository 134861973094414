import React from "react";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import StripeGateway from "../StripeGateway/StripeGateway";
import PaymentError from "../../UI/PaymentError";
import TestCardsContainer from "../../TestMode/TestCardsContainer";
import TokyoGateway from "./../TokyoGateway/TokyoGateway";
import { PG } from "../../../constants/enums/pg";
import { Mode } from "./../../../constants/enums/mode";
import { PaymentMethod } from "./../../../constants/enums/paymentMethod";
import CheckoutTooltip from "./../../UI/CheckoutTooltip";
import AuthNetGateway from "../AuthNetGateway/AuthNetGateway";

const Payment = () => {
  const { intentDetails, paymentError, mode, paymentMethod } =
    useOneTimePaymentsContext();

  const renderPg = () => {
    switch (intentDetails.pg) {
      case PG.STRIPE:
        return <StripeGateway />;
      case PG.AUTHNET:
        return <AuthNetGateway paymentMethod={paymentMethod} />;
      case PG.TOKYO:
        return <TokyoGateway paymentMethod={paymentMethod} />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`overflow-y-auto overflow-x-hidden sm:h-[calc(100vh-473px)] sm:px-2 flex flex-col gap-4 relative`}
      id="payment"
    >
      {paymentError && <PaymentError paymentError={paymentError} />}
      {paymentMethod === PaymentMethod.KLARNA && (
        <CheckoutTooltip text="This payment method will work for Klarna US account holders only." />
      )}
      {paymentMethod === PaymentMethod.AFTERPAY && (
        <CheckoutTooltip text="This payment method will work for Afterpay US account holders only." />
      )}
      {renderPg()}
      {mode === Mode.TEST && paymentMethod === PaymentMethod.CARD && (
        <TestCardsContainer pg={intentDetails.pg} />
      )}
    </div>
  );
};

export default Payment;
