import { captureException } from "@sentry/react";
import axios from "../../axios";
import { createEvent, createSubsEvent } from "./eventContract";

export const sendEvent = async (intentId, eventType, eventData = {}) => {
  const event = createEvent(intentId, eventType, eventData);
  try {
    await axios.post("/ui-event/ingest", event);
    console.log("[📣Event]:", event.eventType);
  } catch (error) {
    captureException(error);
  }
};

export const sendSubsEvent = async (
  subscriptionId,
  eventType,
  eventData = {}
) => {
  const event = createSubsEvent(subscriptionId, eventType, eventData);
  try {
    await axios.post("/ui-event/subs/ingest", event);
    console.log("[📣Event]:", event.eventType);
  } catch (error) {
    captureException(error);
  }
};
