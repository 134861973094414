import React from "react";
import { toast } from "react-hot-toast";
import { FaCopy } from "react-icons/fa";

const TestCard = ({ card, pg }) => {
  const copyCardDetails = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .catch((err) =>
          toast.error("Failed to copy to clipboard:", { position: "top-right" })
        );
      toast.success("Copied to clipboard", { position: "top-right" });
    } else {
      toast.error("Failed to copy to clipboard", { position: "top-right" });
    }
  };

  return (
    <>
      {/* Card Brand */}
      <div className="flex flex-col items-start justify-center">
        <div className="text-sm font-bold tracking-widest uppercase">
          {card.card}
        </div>
        {/* Card Details */}
        <div className="flex flex-col">
          <div className="flex items-center gap-1 mb-2">
            <div className="font-mono text-sm tracking-wider ">
              {card.cardNumber}
            </div>
            <FaCopy
              size={10}
              className="text-gray-500 cursor-pointer"
              onClick={() => copyCardDetails(card.cardNumber)}
            />
          </div>
          <div className="flex justify-between">
            {/* Expiry Date */}
            <div className="text-xs">
              <span className="block text-gray-400">Expires</span>
              <div className="flex items-center gap-1 ">
                <span className="block text-xs">{card.expiryDate}</span>
                <FaCopy
                  size={10}
                  className="text-gray-500 cursor-pointer"
                  onClick={() => copyCardDetails(card.expiryDate)}
                />
              </div>
            </div>

            {/* CVV/CSC */}
            <div className="text-xs">
              <span className="block text-gray-400">CSC</span>
              <div className="flex items-center gap-1 ">
                <span className="block text-xs">{card.csc}</span>
                <FaCopy
                  size={10}
                  className="text-gray-500 cursor-pointer"
                  onClick={() => copyCardDetails(card.csc)}
                />
              </div>
            </div>
          </div>

          {/* Card Holder Name */}
          {card.cardHolderName && (
            <div className="pt-4">
              <span className="block text-xs text-gray-400">Card Holder</span>
              <div className="flex items-center gap-1 ">
                <div className="text-xs tracking-widest uppercase">
                  {card.cardHolderName}
                </div>
                <FaCopy
                  size={10}
                  className="text-gray-500 cursor-pointer"
                  onClick={() => copyCardDetails(card.cardHolderName)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TestCard;
