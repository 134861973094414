import React from "react";
import { Button, Skeleton } from "antd";
import useTriggerPayment from "../../hooks/OneTimePayment/useTriggerPayment";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { sendEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/enums/eventNames";
import usePostIngestBillingAddress from "../../hooks/OneTimePayment/usePostIngestBillingAddress";
import { PaymentMethod } from "../../constants/enums/paymentMethod";

const ProceedButton = () => {
  const { triggerPayment, payBtnLoading } = useTriggerPayment();
  const {
    isPaymentBtnDisabled,
    xIntentId,
    isBillingDetailsIngested,
    billingDetails,
    paymentMethod,
    payPalBtnLoading,
  } = useOneTimePaymentsContext();
  const { postIngestBillingAddress } = usePostIngestBillingAddress();

  const handlePay = async () => {
    sendEvent(xIntentId, EVENT_NAMES.PAY_BUTTON_CLICKED, {});
    if (!isBillingDetailsIngested) {
      await postIngestBillingAddress(billingDetails, false);
    }
    await triggerPayment();
  };

  const renderPayBtn = () => {
    switch (paymentMethod) {
      case PaymentMethod.PAYPAL:
        return (
          <>
            <Skeleton
              active
              loading={payPalBtnLoading}
              paragraph={{ rows: 2, width: "100%" }}
              title={false}
            />
            <div id="citcon-client-container"></div>
          </>
        );
      default:
        return (
          <Button
            size="large"
            className="font-sans font-semibold"
            block
            onClick={handlePay}
            loading={payBtnLoading}
            disabled={isPaymentBtnDisabled}
          >
            Pay
          </Button>
        );
    }
  };

  return <div className="mt-4 sm:mt-6">{renderPayBtn()}</div>;
};

export default ProceedButton;
