/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "../../config";

const SubsLinkRedirect = () => {
  const { shortCode } = useParams();
  useEffect(() => {
    window.location.href = config.api_path + `/subscription/link/${shortCode}`;
  }, []);

  return (
    <div className="flex items-center justify-center h-[100vh]">
      <div className="circular-loader" />
    </div>
  );
};

export default SubsLinkRedirect;
