/* eslint-disable react-hooks/exhaustive-deps */
import { Country } from "country-state-city";

import { useEffect, useState } from "react";

import axios from "../../axios";
import { fieldNames } from "../../constants/fieldNames";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";
import {
  getCurrencyCountryName,
  getCurrencyName,
  getCurrencySymbol,
} from "../../utils/IntlHelper";

import useSubsPaymentMethod from "./useSubsPaymentMethod";
import useSubsPostIngestBillingAddress from "./useSubsPostIngestBillingAddress";
import { sendSubsEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/enums/eventNames";

const useSubsPrefetch = () => {
  const {
    subscriptionId,
    setCurrency,
    setStatus,
    merchantName,
    setMerchantName,
    setLoading,
    setLoadingMain,
    setMode,
    setInterval,
    setIntervalCount,
    setCycleCount,
    setAmount,
    setCallbackUrl,
    billingDetails,
    setBillingDetails,
    setUpfrontAmount,
    productDetails,
    setProductDetails,
    setTrialPeriodInterval,
    setTrialPeriodCount
  } = useSubscriptionContext();

  const [currenciesOptionsList, setCurrenciesOptionsList] = useState([]);
  const { getSubsPaymentMethod, paymentMethodList } = useSubsPaymentMethod();
  const { subsPostIngestBillingAddress } = useSubsPostIngestBillingAddress();

  // Setters
  const setDropdownCurrencies = (data) => {
    let currenciesOptionsList = [];
    currenciesOptionsList.push({
      label: getCurrencySymbol(data.primary.currency),
      value: data.primary.currency,
      searchValue:
        getCurrencyCountryName(data.primary.currency) +
        " - " +
        getCurrencyName(data.primary.currency) +
        " ",
    });

    data.others.forEach((element) => {
      currenciesOptionsList.push({
        label: getCurrencySymbol(element),
        value: element,
        searchValue:
          getCurrencyCountryName(element) +
          " - " +
          getCurrencyName(element) +
          " ",
      });
    });

    setCurrenciesOptionsList(currenciesOptionsList);
  };

  const setData = (data) => {
    setAmount(data?.primary?.amount);
    setUpfrontAmount(data?.primary?.upfrontAmount);
    setCurrency(data?.primary?.currency);
    setMerchantName(data?.merchantName);
    setMode(data?.mode);
    setInterval(data?.interval);
    setIntervalCount(data?.intervalCount);
    setCycleCount(data?.cycleCount);
    setCallbackUrl(data?.callbackUrl);
    setDropdownCurrencies(data);
    setProductDetails(data?.productPage);
    setTrialPeriodCount(data?.trialPeriodCount);
    setTrialPeriodInterval(data?.trialPeriodInterval);
  };

  // Handlers
  const handleBillingDetails = async (data) => {
    setBillingDetails({
      ...billingDetails,
      [fieldNames.name]: data?.name,
      [fieldNames.email]: data?.email,
      [fieldNames.pincode]: data?.pinCode,
      [fieldNames.country]: data?.country
        ? data?.country + "-" + Country.getCountryByCode(data?.country)?.name
        : null,
    });

    if (data?.name && data?.email && data?.pinCode && data?.country) {
      const values = {
        [fieldNames.name]: data?.name,
        [fieldNames.email]: data?.email,
        [fieldNames.pincode]: data?.pinCode,
        [fieldNames.country]: data?.country,
      };

      await subsPostIngestBillingAddress(values, false);
    }
  };

  useEffect(() => {
    const subsPrefetch = async () => {
      setLoadingMain(true);
      try {
        const { data } = await axios.post(
          `/subscription/prefetch-info/${subscriptionId}`
        );
        setData(data);
        await handleBillingDetails(data);
        await getSubsPaymentMethod(data?.primary?.currency);
        sendSubsEvent(subscriptionId, EVENT_NAMES.CHECKOUT_OPEN, {});
        setLoading(false);
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoadingMain(false);
      }
    };

    subsPrefetch();
  }, []);

  return {
    merchantName,
    currenciesOptionsList,
    billingDetails,
    setBillingDetails,
    getSubsPaymentMethod,
    paymentMethodList,
    productDetails,
  };
};

export default useSubsPrefetch;
