import React, { useEffect } from "react";
import { useGetIntent } from "../../../hooks/OneTimePayment/useGetIntent";
import GridBackground from "../../UI/GridBackground";
import { ImSpinner2 } from "react-icons/im";
import Header from "../Header";
import PaymentStatusError from "../../StatusScreens/PaymentStatusError";
import SubscriptionFailedRetry from "../../StatusScreens/SubscriptionFailedRetry";
import MensenSuccessScreen from "./MensenSuccessScreen";

const MensenConfirmation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const xIntentId = urlParams.get('xpay_intent_id');
  const { intentDetails, status, loader } = useGetIntent({
    xIntentId: xIntentId,
  });

  const renderContent = () => {
    switch (status) {
      case "SUCCESS":
        return <MensenSuccessScreen intentDetails={intentDetails} />;
      case "CREATED":
      case "INCOMPLETE":
        return <SubscriptionFailedRetry />;
      case "ERROR":
      default:
        return <PaymentStatusError id={xIntentId} />;
    }
  };

  return (
    <GridBackground>
      <div
        className="bg-white h-full w-full max-w-[32rem] drop-shadow-2xl sm:rounded-2xl sm:py-9 sm:px-12 pt-8 pb-6 px-6 backdrop-blur shadow-2xl "
        id="container"
      >
        {loader ? (
          <div className="flex items-center justify-center h-[80vh] text-3xl">
            <ImSpinner2 className="animate-spin" />
          </div>
        ) : (
          <div>
            <Header />
            {renderContent()}
          </div>
        )}
      </div>
    </GridBackground>
  );
};

export default MensenConfirmation;
