export const SUBS_STATUS = Object.freeze({
  ACTIVE: "ACTIVE",

  COMPLETED: "COMPLETED",
  ENDED: "ENDED",

  INACTIVE: "INACTIVE",
  UNPAID: "UNPAID",

  INCOMPLETE: "INCOMPLETE",
  CREATED: "CREATED",
  CANCELLED: "CANCELLED",
  LOADING: "LOADING",
  ERROR: "ERROR",
  TERMINAL_STATE: "TERMINAL_STATE",
  TRIALING: "TRIALING",
});
