import React from "react";
import { Divider } from "antd";
import { ImSpinner2 } from "react-icons/im";

const SubsProductAmount = ({
  amountLoading,
  currency,
  amount,
  upfrontAmount,
}) => {
  return (
    <div>
      <Divider className="my-4 sm:my-6" />
      <div className="flex items-center justify-between gap-4">
        <div>
          <span className="text-xl font-semibold">Paying Now</span>{" "}
        </div>
        <div className="text-right">
          {amountLoading ? (
            <div className="flex items-center justify-center text-sm">
              <ImSpinner2 className="mr-2 animate-spin" />
              <span className="animate-pulse">Calculating...</span>
            </div>
          ) : (
            <div className="font-semibold">
              {upfrontAmount ? (
                <span>
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                    minimumFractionDigits: 2,
                  }).format(upfrontAmount / 100)}
                </span>
              ) : (
                <span>
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                    minimumFractionDigits: 2,
                  }).format(amount / 100)}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubsProductAmount;
