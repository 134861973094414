import axios from "axios";
import config from "../config";

const instance = axios.create({
  baseURL: config.api_path,
  timeout: 20000,
});

const urlParams = new URLSearchParams(window.location.search);
const secret = urlParams.get("secret");

if (process.env.REACT_APP_XPAY_ENV === "dev") {
  instance.interceptors.request.use((req) => {
    return fetch("https://api.ipify.org?format=text")
      .then((response) => response.text())
      .then((ip) => {
        req.headers = {
          ...(secret ? { Authorization: `Secret ${secret}` } : {}),
          "x-forwarded-for": ip,
        };
        return req;
      });
  });
} else {
  instance.interceptors.request.use((req) => {
    req.headers = {
      ...(secret ? { Authorization: `Secret ${secret}` } : {}),
    };
    return req;
  });
}

export default instance;
