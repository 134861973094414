/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useSubscriptionContext } from "../../../contexts/SubscriptionContext";
// import posthog from "posthog-js";

const SubsStripeTokCheckout = () => {
  const {
    setIsPaymentBtnDisabled,
    loading,
    setStripeElement,
    setStripeCardElement,
  } = useSubscriptionContext();
  const elements = useElements();

  useEffect(() => {
    if (!elements) return;
    setStripeElement(elements);

    setStripeCardElement(elements.getElement(CardNumberElement));
    setIsPaymentBtnDisabled(false);

    // if (CardElement) {
    //   paymentElement.on("ready", (event) => {
    //     posthog.capture("stripe_ready", { stripe_event: event });
    //   });

    //   paymentElement.on("change", (event) => {
    //     posthog.capture("stripe_change", { stripe_event: event });
    //   });

    //   paymentElement.on("focus", (event) => {
    //     posthog.capture("stripe_focus", { stripe_event: event });
    //   });

    //   paymentElement.on("blur", (event) => {
    //     posthog.capture("stripe_blur", { stripe_event: event });
    //   });

    //   paymentElement.on("escape", (event) => {
    //     posthog.capture("stripe_escape", { stripe_event: event });
    //   });

    //   paymentElement.on("click", (event) => {
    //     posthog.capture("stripe_click", { stripe_event: event });
    //   });

    //   paymentElement.on("loaderror", (event) => {
    //     posthog.capture("stripe_loaderror", { stripe_event: event });
    //   });

    //   paymentElement.on("loaderstart", (event) => {
    //     posthog.capture("stripe_loaderstart", { stripe_event: event });
    //   });
    // }
  }, [elements]);

  return (
    <>
      {!loading && (
        <div className="pb-4">
          <div className="mb-6">
            <div className="text-[14px] pb-3 text-[#30313d] leading-none">
              Card number
            </div>
            <CardNumberElement
              className="w-full border border-[#e0e0e0] rounded-[8px] p-3"
              options={{
                style: {
                  base: {
                    fontFamily: "Outfit, sans-serif",
                    fontSize: "16px",
                  },
                  invalid: {
                    color: "#ff4d4f",
                  },
                },
              }}
            />
          </div>
          <div className="mb-6 grid grid-cols-2 gap-3">
            <div>
              <div className="text-[14px] pb-3 text-[#30313d] leading-none">
                Expiration date
              </div>
              <CardExpiryElement
                className="w-full border border-[#e0e0e0] rounded-[8px] p-3"
                options={{
                  style: {
                    base: {
                      fontFamily: "Outfit, sans-serif",
                      fontSize: "16px",
                    },
                    invalid: {
                      color: "#ff4d4f",
                    },
                  },
                }}
              />
            </div>
            <div>
              <div className="text-[14px] pb-3 text-[#30313d] leading-none">
                Security code
              </div>
              <CardCvcElement
                className="w-full border border-[#e0e0e0] rounded-[8px] p-3"
                options={{
                  style: {
                    base: {
                      fontFamily: "Outfit, sans-serif",
                      fontSize: "16px",
                    },
                    invalid: {
                      color: "#ff4d4f",
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="text-[#6d6e78] text-xs mt-2">
            By providing your card information, you allow xPay to charge your
            card for future payments in accordance with their terms.
          </div>
        </div>
      )}
    </>
  );
};
export default SubsStripeTokCheckout;
