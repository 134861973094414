/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from "antd";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";

const UpdateSubsTransDetails = () => {
  const {
    currency,
    amount,
    interval,
    intervalCount,
    cycleCount,
    merchantName,
  } = useSubscriptionContext();

  return (
    <div>
      <Divider className="my-4 sm:my-6" />
      <div className="flex items-center justify-between gap-4">
        <div>
          <span className="">Paying to</span>{" "}
          <span className="font-semibold">{merchantName}</span>
        </div>
        <div className="text-right">
          <div className="flex gap-1">
            <span className="font-semibold">
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currency,
                minimumFractionDigits: 2,
              }).format(amount / 100)}
            </span>
            now,
          </div>
        </div>
      </div>
      <div className="text-sm text-right text-gray-600">
        then{" "}
        {Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
        }).format(amount / 100)}{" "}
        every {intervalCount} {interval?.toLowerCase()}
        {intervalCount > 1 ? "s" : ""}, for next {cycleCount - 1} payments
      </div>
    </div>
  );
};

export default UpdateSubsTransDetails;
