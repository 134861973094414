import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useState } from "react";
import { toast } from "react-hot-toast";

export const useDeleteSavedPM = () => {
  const { xIntentId, setStatus, setSavedPMDetails } =
    useOneTimePaymentsContext();
  const [loading, setLoading] = useState(false);
  const deleteSavedPM = async ({ pmId, onManagePMDrawerClose }) => {
    setLoading(true);
    try {
      await axios.post(`/payments/paymentMethod/delete`, {
        intentId: xIntentId,
        pmId: pmId,
      });
      setSavedPMDetails((prev) => prev.filter((pm) => pm.pmId !== pmId));
      toast.success("Payment method deleted successfully");
    } catch (error) {
      ErrorHandler(error, setStatus);
      onManagePMDrawerClose();
    } finally {
      setLoading(false);
    }
  };

  return { deleteSavedPM, loading };
};
