import axios from "../../axios";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";

const useSubsPaymentConfirmation = () => {
  const { setStatus, subscriptionId, callbackUrl, setErrorMessage, secret } =
    useSubscriptionContext();

  const subscriptionTriggerAck = async ({ tokenId }) => {
    try {
      const { data } = await axios.post(
        `/subscription/trigger-ack/${subscriptionId}`,
        {
          tokenId: tokenId ? tokenId : null,
        }
      );
      setStatus(data.status);
      data.errorMessage && setErrorMessage(data.errorMessage);
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  const subsTriggerRedirect = async () => {
    try {
      if (!callbackUrl) {
        ErrorHandler(new Error("callbackUrl not found"), setStatus);
        return;
      }
      const callback = new URL(callbackUrl);
      callback.searchParams.set("subscription_id", subscriptionId);
      callback.searchParams.set("secret", secret);
      setTimeout(() => window.location.replace(callback.href), 5000);
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  return {
    subsTriggerRedirect,
    subscriptionTriggerAck,
  };
};

export default useSubsPaymentConfirmation;
