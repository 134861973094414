import React from "react";
import { Radio, Spin } from "antd";
import { ImSpinner2 } from "react-icons/im";
import { sendEvent } from "../../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../../constants/enums/eventNames";
import RecentPMs from "../RecentPMs";
import usePostIngestBillingAddress from "../../../hooks/OneTimePayment/usePostIngestBillingAddress";

const Method = ({
  paymentMethodList,
  setPaymentMethod,
  triggerIntent,
  loading,
  currency,
  xIntentId,
  savedPMDetails,
  setSavePMDrawerOpen,
  setSavePMSelected,
  setForm,
  setManageSavedPMDrawerOpen,
  billingDetails,
  isBillingDetailsIngested,
}) => {
  const { postIngestBillingAddress } = usePostIngestBillingAddress();

  const onChange = async (e) => {
    const value = e.target.value;
    setPaymentMethod(value);
    await triggerIntent({ value, currency });
    if (!isBillingDetailsIngested) {
      await postIngestBillingAddress(billingDetails, false);
    }
    await sendEvent(xIntentId, EVENT_NAMES.PAYMENT_METHOD_SELECTED, {
      method: value,
    });
  };

  return (
    <Spin
      spinning={loading}
      indicator={<ImSpinner2 className="animate-spin" />}
      size="large"
      className="sm:h-[calc(100vh-473px)]"
    >
      <div
        className={`flex flex-col gap-4 overflow-y-auto overflow-x-hidden sm:h-[calc(100vh-473px)] sm:px-2`}
        id="method"
      >
        {savedPMDetails && savedPMDetails.length > 0 && (
          <RecentPMs
            savedPMDetails={savedPMDetails}
            setSavePMDrawerOpen={setSavePMDrawerOpen}
            setSavePMSelected={setSavePMSelected}
            setForm={setForm}
            setManageSavedPMDrawerOpen={setManageSavedPMDrawerOpen}
          />
        )}
        <div className={`flex justify-between`}>
          <Radio.Group onChange={onChange}>
            <div className="grid w-[calc(100vw-4rem)] sm:w-full grid-cols-3 gap-2 sm:gap-3 pb-4">
              {paymentMethodList?.map((option) => {
                return (
                  <Radio.Button
                    value={option.value}
                    className={`flex items-center justify-center sm:min-w-[126px] min-h-[100px] h-full p-2`}
                    key={option.value}
                  >
                    <div className="flex flex-col items-center justify-center gap-2 font-medium">
                      <span className="text-4xl">{option.icon}</span>
                      {option.showText && (
                        <span className="font-sans text-sm text-center">
                          {option.title}
                        </span>
                      )}
                    </div>
                  </Radio.Button>
                );
              })}
            </div>
          </Radio.Group>
        </div>
      </div>
    </Spin>
  );
};

export default Method;
