/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal } from "antd";

import { GoCreditCard } from "react-icons/go";

import { fieldNames } from "../../../constants/fieldNames";
import cscIcon from "../../../assets/Icons/card-security-code.png";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import ChargeCardInputField from "../../UI/FormFields/ChargeCardInputField";
import { STATUS } from "../../../constants/enums/status";

const ChargeCard = () => {
  const {
    iframeUrl,
    setPaymentError,
    setStatus,
    isIframeModalOpen,
    setIsIframeModalOpen,
  } = useOneTimePaymentsContext();

  const handleIframeModalClose = () => {
    setIsIframeModalOpen(false);
  };

  const ERROR_MESSAGE =
    "Your payment was declined by the payment method provider.";

  useEffect(() => {
    const handleIframeMessage = (event) => {
      if (event.origin !== window.origin) {
        console.log("❌[MisMatch]: Event Origin:", event.origin);
        return;
      }

      const { status, errorMessage } = event.data;
      if (status === STATUS.SUCCESS) {
        setStatus(STATUS.SUCCESS);
        setIsIframeModalOpen(false);
      } else if (errorMessage) {
        setPaymentError(errorMessage);
        setIsIframeModalOpen(false);
      } else if (status === STATUS.FAILED) {
        setPaymentError(ERROR_MESSAGE);
        setIsIframeModalOpen(false);
      }
    };

    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, [iframeUrl]);

  return (
    <>
      <ChargeCardInputField
        name={fieldNames.cardNumber}
        label="Card number"
        required
        placeholder={"1234 1234 1234 1234"}
        suffix={<GoCreditCard size={24} />}
      />
      <div className="grid grid-cols-2 gap-3">
        <ChargeCardInputField
          name={fieldNames.cardValidity}
          label="Expiration date"
          required
          placeholder={"MM/YY"}
        />
        <ChargeCardInputField
          name={fieldNames.cardSecurityCode}
          label="Security code"
          required
          placeholder={"CVV"}
          suffix={<img src={cscIcon} alt="CVV Icon" className="w-6 " />}
        />
      </div>
      <ChargeCardInputField
        name={fieldNames.cardName}
        label="Name on card"
        required
        placeholder={"Cardholder Name"}
      />

      <Modal
        title=""
        open={isIframeModalOpen}
        onCancel={handleIframeModalClose}
        width={490}
        footer={null}
        centered={true}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName="p-0"
        styles={{
          content: {
            padding: "0px",
          },
        }}
      >
        <iframe
          src={iframeUrl}
          title="iFrameHandler"
          style={{
            width: "100%",
            height: "80vh",
            border: "none",
            borderRadius: "8px",
          }}
        ></iframe>
      </Modal>
    </>
  );
};

export default ChargeCard;
