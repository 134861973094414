import { loadStripe } from "@stripe/stripe-js";

import { PG } from "../../constants/enums/pg";
import { STATUS } from "../../constants/enums/status";

import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { useRetrieveTokyoPayment } from "./useRetrieveTokyoPayment";

import { sendEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/enums/eventNames";
import { clearUrlParams } from "./../../utils/ClearUrlParams";
import useTriggerIntent from "./useTriggerIntent";
import { PaymentMethod } from "../../constants/enums/paymentMethod";

export const useHandleAsyncPM = () => {
  const {
    xIntentId,
    setCurrency,
    setStatus,
    setAmount,
    setIntentDetails,
    intentDetails,
    setPaymentMethod,
    setCallbackUrl,
    setPaymentError,
    setMode,
  } = useOneTimePaymentsContext();

  const { triggerIntent } = useTriggerIntent();

  const urlParam = new URLSearchParams(window.location.search);
  const { retrieveTokyoPayment } = useRetrieveTokyoPayment();

  const STANDARD_ERROR_MESSAGE =
    "Your payment was declined by the payment method provider.";

  const handleStripeAsyncPM = async () => {
    const clientSecret = urlParam.get("payment_intent_client_secret");
    if (clientSecret) {
      const stripe = await loadStripe(urlParam.get("pgPublicKey"));
      const { paymentIntent } = await stripe.retrievePaymentIntent(
        clientSecret
      );

      setCallbackUrl(urlParam.get("callbackUrl"));
      setPaymentMethod(urlParam.get("paymentMethod"));
      setCurrency(urlParam.get("currency"));
      setAmount(Number(urlParam.get("amount")));
      setIntentDetails({
        ...intentDetails,
        pg: PG.STRIPE,
        clientSecret: clientSecret,
        pgPublicKey: urlParam.get("pgPublicKey"),
        xIntentId: urlParam.get("xpay_intent_id"),
        callbackUrl: urlParam.get("callbackUrl"),
      });

      clearUrlParams();

      if (paymentIntent.status === "succeeded") {
        setStatus(STATUS.SUCCESS);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_SUCCESS, {});
        return true;
      } else {
        setPaymentError(STANDARD_ERROR_MESSAGE);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
          errorMessage: STANDARD_ERROR_MESSAGE,
        });
      }
    }
  };

  const handleTokyoAsyncPM = async () => {
    const tnxReferenceId = urlParam.get("payment_reference_id");
    const mode = urlParam.get("mode");
    if (tnxReferenceId) {
      const { status, errorMessage } = await retrieveTokyoPayment({
        tnxReferenceId: tnxReferenceId,
        mode: mode,
      });

      setCallbackUrl(urlParam.get("callbackUrl"));
      setPaymentMethod(urlParam.get("paymentMethod"));
      setCurrency(urlParam.get("currency"));
      setAmount(Number(urlParam.get("amount")));
      setMode(mode);

      if (urlParam.get("paymentMethod") === PaymentMethod.PAYPAL) {
        await triggerIntent({
          value: PaymentMethod.PAYPAL,
          currency: urlParam.get("currency"),
        });
      } else {
        setIntentDetails({
          ...intentDetails,
          pg: PG.TOKYO,
          mode: mode,
          tnxReferenceId: tnxReferenceId,
          xIntentId: urlParam.get("xpay_intent_id"),
          callbackUrl: urlParam.get("callbackUrl"),
        });
      }

      clearUrlParams();

      if (status === STATUS.SUCCESS) {
        setStatus(STATUS.SUCCESS);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_SUCCESS, {});
        return true;
      } else {
        setPaymentError(errorMessage || STANDARD_ERROR_MESSAGE);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
          errorMessage: errorMessage || STANDARD_ERROR_MESSAGE,
        });
      }
    }
  };

  const handleAsyncPM = async ({ pg }) => {
    switch (pg) {
      case PG.STRIPE:
        return await handleStripeAsyncPM();
      case PG.TOKYO:
        return await handleTokyoAsyncPM();
      default:
        return false;
    }
  };

  return { handleAsyncPM };
};
