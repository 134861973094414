import React from "react";
import { ReactComponent as SecuredByXPayLogoRC } from "../../assets/securedByXpayLogo.svg";
import BackButton from "./BackButton";
const Header = ({
  setLoadingMain,
  mode,
  merchantLogo,
  callbackUrl,
  xIntentId,
  subscriptionId,
  productDetails,
}) => {
  const handleImageLoad = () => {
    setLoadingMain(false);
  };

  const isMobileDevice =
    typeof window !== "undefined" && window.innerWidth < 1024;

  return (
    <div
      className={`relative ${
        isMobileDevice && productDetails ? "pb-4" : "pb-7"
      }`}
    >
      {isMobileDevice ? (
        <div className="flex items-center justify-between">
          {merchantLogo ? (
            <>
              <img
                src={merchantLogo}
                alt="merchant logo"
                onLoad={handleImageLoad}
                onError={handleImageLoad}
                className="rounded-lg max-h-14 max-w-28 drop-shadow-sm"
              />

              {mode === "TEST" && (
                <div className="text-yellow-600 bg-[#FFF9DE] rounded-full py-[6px] px-3 text-xs absolute left-[calc((100vw-130px)/2)] sm:left-[168px]">
                  Test Mode
                </div>
              )}
              <div>
                <SecuredByXPayLogoRC className="w-16 h-9" />
              </div>
            </>
          ) : (
            <div className={`flex items-center "justify-between w-full`}>
              <SecuredByXPayLogoRC className="w-20 h-10" />
              {mode === "TEST" && (
                <div className="text-yellow-600 bg-[#FFF9DE] rounded-full py-[6px] px-3 text-xs ml-3">
                  Test Mode
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-between">
          {!productDetails && merchantLogo ? (
            <>
              <img
                src={merchantLogo}
                alt="merchant logo"
                onLoad={handleImageLoad}
                onError={handleImageLoad}
                className="rounded-lg max-h-14 max-w-28 drop-shadow-sm"
              />

              {mode === "TEST" && (
                <div className="text-yellow-600 bg-[#FFF9DE] rounded-full py-[6px] px-3 text-xs absolute left-[calc((100vw-130px)/2)] sm:left-[168px]">
                  Test Mode
                </div>
              )}
              <div>
                <SecuredByXPayLogoRC className="w-16 h-9" />
              </div>
            </>
          ) : (
            <div
              className={`flex items-center ${
                productDetails ? "justify-end" : "justify-between"
              } w-full`}
            >
              <SecuredByXPayLogoRC className="w-20 h-10" />
              {mode === "TEST" && (
                <div className="text-yellow-600 bg-[#FFF9DE] rounded-full py-[6px] px-3 text-xs ml-3">
                  Test Mode
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {callbackUrl && (
        <BackButton
          callbackUrl={callbackUrl}
          xIntentId={xIntentId}
          subscriptionId={subscriptionId}
        />
      )}
    </div>
  );
};

export default Header;
