import React from "react";
import { Tooltip } from "antd";
import { AiOutlineProduct } from "react-icons/ai";

const ProductDetailsCard = ({
  merchantLogo,
  productDetails
}) => {
  return (
    <div className="p-8 border border-gray-200 rounded-lg ">
      <div className="flex gap-8 items-center">
        {/* logo */}
        {merchantLogo ? (
          <div>
            <img
              src={merchantLogo}
              alt="merchant logo"
              className="rounded-lg max-h-14 max-w-28 drop-shadow-sm"
            />
          </div>
        ) : (
          <div className="bg-gray-200 max-h-14 max-w-28 flex items-center justify-center rounded-lg p-4">
            <AiOutlineProduct size={24} />
          </div>
        )}

        <div className="flex flex-col gap-4">
          <div className="">
            {/* title */}
            <Tooltip title={productDetails?.name} placement="top">
              <div className="text-lg font-medium truncate max-w-[35ch] w-fit">
                {productDetails?.name}
              </div>
            </Tooltip>
          </div>
          {/* {description} */}
          <div className="text-[#535353] text-sm">
            {productDetails?.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsCard;
