import axios from "../../axios";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";

const useUpdateSubsTrigger = () => {
  const {
    subscriptionId,
    setAmount,
    setCurrency,
    setPg,
    setCallbackUrl,
    setPgPublicKey,
    setLoading,
    setMerchantName,
    setStatus,
    setClientSecret,
    setLoadingMethods,
    setIntentDetails,
  } = useSubscriptionContext();
  const updateSubsTrigger = async ({ value }) => {
    setLoadingMethods(true);
    try {
      const { data } = await axios.post(`/subscription/update/payment-details/trigger`, {
        subscriptionId: subscriptionId,
        paymentMethod: value,
      });

      setPg(data?.pg);
      setIntentDetails(data);
      setCallbackUrl(data?.callbackUrl);
      setPgPublicKey(data?.pgPublicKey);
      setAmount(data?.presentmentAmount);
      setCurrency(data?.presentmentCurrency);
      setMerchantName(data?.merchantName);
      setClientSecret(data?.pgClientSecret);
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { updateSubsTrigger };
};

export default useUpdateSubsTrigger;
