import React from "react";
import { ReactComponent as Affirm } from "../../assets/productFooterIcons/Affirm.svg";
import { ReactComponent as AmazonPay } from "../../assets/productFooterIcons/AmazonPay.svg";
import { ReactComponent as Amex } from "../../assets/productFooterIcons/Amex.svg";
import { ReactComponent as ApplePay } from "../../assets/productFooterIcons/ApplePay.svg";
import { ReactComponent as Discover } from "../../assets/productFooterIcons/Discover.svg";
import { ReactComponent as Klarna } from "../../assets/productFooterIcons/Klarna.svg";
import { ReactComponent as Mastercard } from "../../assets/productFooterIcons/Mastercard.svg";
import { ReactComponent as Visa } from "../../assets/productFooterIcons/Visa.svg";
import { Divider } from "antd";

const paymentMethods = [
  {
    name: "Visa",
    icon: <Visa className="h-6" />,
  },
  {
    name: "Mastercard",
    icon: <Mastercard className="h-6" />,
  },
  {
    name: "Amex",
    icon: <Amex className="h-6" />,
  },
  {
    name: "Discover",
    icon: <Discover className="h-6" />,
  },
  {
    name: "ApplePay",
    icon: <ApplePay className="h-6" />,
  },
  {
    name: "AmazonPay",
    icon: <AmazonPay className="h-6" />,
  },
  {
    name: "Klarna",
    icon: <Klarna className="h-6" />,
  },
  {
    name: "Affirm",
    icon: <Affirm className="h-6" />,
  },
];

const ProductFooter = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <Divider />
      <div className="flex items-center justify-center gap-5 flex-wrap">
        {paymentMethods.map(({ icon }) => (
          <div key={icon.type.name} className="h-6 w-8">
            {icon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductFooter;
