/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Spin } from "antd";

import { useSubscriptionContext } from "../../contexts/SubscriptionContext.jsx";

import { ImSpinner2 } from "react-icons/im";

import SubsMethod from "../Subscription/Steps/SubsMethod";
import SubsPayment from "../Subscription/Steps/SubsPayment.jsx";

import Header from "../UI/Header.jsx";
import SubsPaymentStatusView from "../Subscription/SubsPaymentStatusView.jsx";
import SubsStaticUserBox from "./SubsStaticUserBox.jsx";
import SubsUpdatePMButton from "./SubsUpdatePMButton.jsx";
import useUpdateSubsPrefetch from "../../hooks/UpdateSubsPM/useUpdateSubsPrefetch.js";
import { useSubsGetMerchantLogo } from "../../hooks/Subscription/useSubsGetMerchantLogo.js";
import useUpdateSubsTrigger from "../../hooks/UpdateSubsPM/useUpdateSubsTrigger.js";
import UpdateSubsTransDetails from "./UpdateSubsTransDetails.jsx";

const UpdateSubsPMContainer = () => {
  const {
    subscriptionId,
    loading,
    status,
    intentDetails,
    loadingMain,
    setLoadingMain,
    mode,
    loadingMethods,
    setPaymentMethod,
    currency,
    errorMessage,
    callbackUrl,
    setStatus,
  } = useSubscriptionContext();

  const { billingDetails, paymentMethodList, productDetails } =
    useUpdateSubsPrefetch();

  const { merchantLogo } = useSubsGetMerchantLogo({
    subscriptionId,
    setLoadingMain,
    setStatus,
  });

  const { updateSubsTrigger } = useUpdateSubsTrigger();
  const [open, setOpen] = useState(false);

  const renderContent = () => {
    if (status) {
      return (
        <SubsPaymentStatusView status={status} errorMessage={errorMessage} isUpdate={true} />
      );
    }

    return (
      <>
        <Spin
          spinning={loading}
          indicator={<ImSpinner2 className="animate-spin" />}
          size="large"
          className=""
        >
          <div className="flex flex-col w-full h-full gap-7">
            {/* User details Sections */}
            <SubsStaticUserBox
              open={open}
              setOpen={setOpen}
              billingDetails={billingDetails}
              productDetails={productDetails}
            />

            {/* Payment Details Sections */}
            <div className="flex items-center justify-between w-full gap-3">
              <div>Update Payment Method</div>
            </div>

            {/* Payment Body */}
            {!intentDetails ? (
              <SubsMethod
                paymentMethodList={paymentMethodList}
                setPaymentMethod={setPaymentMethod}
                loading={loadingMethods}
                triggerIntent={updateSubsTrigger}
                currency={currency}
              />
            ) : (
              <SubsPayment />
            )}
          </div>

          {/* Footer Sections */}
          <div className="fixed sm:w-[416px] bottom-9 hidden sm:block bg-white">
            <UpdateSubsTransDetails />
            <SubsUpdatePMButton />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <>
      <div
        className="bg-white h-full w-full max-w-[32rem] sm:drop-shadow-2xl sm:rounded-2xl sm:py-9 sm:px-12 pt-8 pb-[157px] px-6 sm:backdrop-blur sm:shadow-2xl overflow-y-auto relative"
        id="container"
      >
        <Spin
          spinning={loadingMain}
          indicator={<ImSpinner2 className="animate-spin" />}
          size="large"
        >
          {/* Header */}
          <Header
            setLoadingMain={setLoadingMain}
            mode={mode}
            merchantLogo={merchantLogo}
            callbackUrl={callbackUrl}
            subscriptionId={subscriptionId}
            productDetails={productDetails}
          />
          {renderContent()}
        </Spin>
      </div>
      {/* Mobile Views */}
      {!status && !loading && (
        <div className="fixed w-full sm:w-[416px] bottom-0 pb-6 px-6 sm:hidden bg-white">
          <UpdateSubsTransDetails />
          <SubsUpdatePMButton />
        </div>
      )}
    </>
  );
};

export default UpdateSubsPMContainer;
