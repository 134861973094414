import React from "react";
import SubsContainer from "./SubsContainer";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext.jsx";
import useSubsPrefetch from "../../hooks/Subscription/useSubsPrefetch.js";
import { useSubsGetMerchantLogo } from "../../hooks/Subscription/useSubsGetMerchantLogo.js";
import SubsProductDetails from "./SubsProductDetails.jsx";

const SubsInitilizationContainer = () => {
  const {
    subscriptionId,
    setStatus,
    setLoadingMain,
    amount,
    currency,
    interval,
    intervalCount,
    amountLoading,
    upfrontAmount,
    cycleCount,
    trialPeriodCount,
    trialPeriodInterval,
  } = useSubscriptionContext();

  const {
    merchantName,
    currenciesOptionsList,
    billingDetails,
    setBillingDetails,
    getSubsPaymentMethod,
    paymentMethodList,
    productDetails,
  } = useSubsPrefetch();

  const { merchantLogo } = useSubsGetMerchantLogo({
    subscriptionId,
    setLoadingMain,
    setStatus,
  });

  return (
    <div className="flex sm:h-screen w-full justify-center">
      {productDetails && (
        <SubsProductDetails
          merchantLogo={merchantLogo}
          productDetails={productDetails}
          amount={amount}
          currency={currency}
          upfrontAmount={upfrontAmount}
          amountLoading={amountLoading}
          merchantName={merchantName}
          currenciesOptionsList={currenciesOptionsList}
          interval={interval}
          intervalCount={intervalCount}
          cycleCount={cycleCount}
          trialPeriodCount={trialPeriodCount}
          trialPeriodInterval={trialPeriodInterval}
        />
      )}
      <SubsContainer
        merchantName={merchantName}
        currenciesOptionsList={currenciesOptionsList}
        billingDetails={billingDetails}
        setBillingDetails={setBillingDetails}
        getSubsPaymentMethod={getSubsPaymentMethod}
        paymentMethodList={paymentMethodList}
        merchantLogo={merchantLogo}
      />
    </div>
  );
};

export default SubsInitilizationContainer;
