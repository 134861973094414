/* eslint-disable react-hooks/exhaustive-deps */
import { Country } from "country-state-city";

import { useEffect } from "react";

import axios from "../../axios";
import { fieldNames } from "../../constants/fieldNames";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";

import { sendSubsEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/enums/eventNames";
import useUpdateSubsTrigger from "./useUpdateSubsTrigger";

const useUpdateSubsPrefetch = () => {
  const {
    subscriptionId,
    setStatus,
    merchantName,
    setMerchantName,
    setLoading,
    setLoadingMain,
    setMode,
    setCallbackUrl,
    billingDetails,
    setBillingDetails,
    productDetails,
    setPaymentMethod,
    setInterval,
    setIntervalCount,
    setCycleCount,
    setAmount,
    setCurrency,
  } = useSubscriptionContext();

  const { updateSubsTrigger } = useUpdateSubsTrigger();

  // Setters
  const setData = (data) => {
    setMerchantName(data?.merchantName);
    setInterval(data?.interval);
    setIntervalCount(data?.intervalCount);
    setCycleCount(data?.remainingCycleCount);
    setAmount(data?.amount);
    setCurrency(data?.currency);
    setMode(data?.mode);
    setCallbackUrl(data?.callbackUrl);
    setBillingDetails({
      ...billingDetails,
      [fieldNames.name]: data?.name,
      [fieldNames.email]: data?.email,
      [fieldNames.pincode]: data?.pinCode,
      [fieldNames.country]: data?.country
        ? data?.country + "-" + Country.getCountryByCode(data?.country)?.name
        : null,
    });
    setPaymentMethod(data.paymentMethod);
  };

  useEffect(() => {
    const subsPrefetch = async () => {
      setLoadingMain(true);
      try {
        const { data } = await axios.post(
          `/subscription/update/payment-details/prefetch/${subscriptionId}`
        );
        setData(data);
        sendSubsEvent(subscriptionId, EVENT_NAMES.CHECKOUT_OPEN, {});
        await updateSubsTrigger({ value: data.paymentMethod });
        setLoading(false);
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoadingMain(false);
      }
    };

    subsPrefetch();
  }, []);

  return {
    merchantName,
    billingDetails,
    setBillingDetails,
    productDetails,
  };
};

export default useUpdateSubsPrefetch;
