import { Button } from "antd";
import Loader from "../UI/Loader";
import failureAnimation from "../../assets/Animations/failureAnimation.json";
import { IoRefreshOutline } from 'react-icons/io5';

const SubscriptionFailedRetry = ({ message, update = false }) => {
  const handleRetry = () => {
    window.location.href = getPaymentUrl(update);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2 h-[75vh]">
      <Loader
        json={failureAnimation}
        width="500px"
        height="250px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="250px"
        mobileWidth="320px"
      />
      <p className="pt-8 mb-12 text-lg text-center">
        {message || "Seems like the payment didn't go through!"}
      </p>
      <Button
        size="large"
        className="font-sans "
        block
        onClick={handleRetry}
        icon={<IoRefreshOutline />}
      >
        Retry
      </Button>
    </div>
  );
};

const getPaymentUrl = (update) => {
  const search = window.location.search;
  return update ? `/subscription/update/${search}` : `/${search}`;
};

export default SubscriptionFailedRetry;
