export const createEvent = (intentId, eventType, eventData = {}) => {
  return {
    intentId,
    eventTime: Date.now(),
    eventType,
    eventData,
  };
};

export const createSubsEvent = (subscriptionId, eventType, eventData = {}) => {
  return {
    subscriptionId,
    eventTime: Date.now(),
    eventType,
    eventData,
  };
};
