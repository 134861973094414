import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useState } from "react";
import { STATUS } from "../../constants/enums/status";

export const useChargeToken = () => {
  const {
    xIntentId,
    setPaymentError,
    setStatus,
    currency,
    amount,
    secret,
    paymentMethod,
    setIntentDetails,
  } = useOneTimePaymentsContext();

  const [loading, setLoading] = useState(false);

  const generateReturnUrl = () => {
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set("paymentMethod", paymentMethod);
    returnUrl.searchParams.set("currency", currency);
    returnUrl.searchParams.set("amount", amount);
    returnUrl.searchParams.set("secret", secret);

    return returnUrl.href;
  };

  const chargeToken = async ({ savePMSelected, onSavePMDrawerClose }) => {
    setLoading(true);
    try {
      const { data } = await axios.post(`/payments/charge-token`, {
        intentId: xIntentId,
        pmId: savePMSelected?.pmId,
        currency: currency,
        confirmationUrl: generateReturnUrl(),
      });

      if (data?.status === STATUS.SUCCESS) {
        setIntentDetails((prev) => ({
          ...prev,
          callbackUrl: data?.callbackUrl,
          xIntentId,
        }));
        onSavePMDrawerClose();
        setStatus(STATUS.SUCCESS);
      } else {
        setPaymentError(data?.errorMessage);
      }
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { chargeToken, loading };
};
