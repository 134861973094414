/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ProductDetailsCard from "../UI/ProductDetailsCard";
import SubsProductAmount from "./SubsProductAmount";
import { motion } from "framer-motion";
import ProductFooter from "../UI/ProductFooter";
import SubsCalendar from "../UI/SubsCalendar";
import { Divider, Spin } from "antd";
import { ImSpinner2 } from "react-icons/im";

const SubsProductDetails = ({
  merchantLogo,
  productDetails,
  amount,
  currency,
  upfrontAmount,
  amountLoading,
  interval,
  intervalCount,
  cycleCount,
  trialPeriodCount,
  trialPeriodInterval,
}) => {
  const [productAmount, setProductAmount] = useState(amount);

  useEffect(() => {
    if (trialPeriodCount) {
      setProductAmount(0);
    }
  }, [amount, trialPeriodCount]);

  return (
    <div
      className="bg-white sm:pb-9 sm:pt-28 sm:pl-12 pt-8 pl-6 relative h-full w-full max-w-[46rem] sm:pr-[128px] hidden lg:block border-l-[1px] border-gray-300"
      id="container"
    >
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className="h-full flex flex-col justify-between"
      >
        <div>
          <ProductDetailsCard
            merchantLogo={merchantLogo}
            productDetails={productDetails}
          />
          <SubsProductAmount
            amountLoading={amountLoading}
            currency={currency}
            amount={productAmount}
            upfrontAmount={upfrontAmount}
          />
          <Divider />
          <div className="mt-6">
            <div className="">Upcoming Payments</div>
            <Spin
              spinning={amountLoading}
              indicator={<ImSpinner2 className="animate-spin" />}
              size="large"
            >
              <div className="max-h-[calc(100vh-520px)] overflow-auto">
                <SubsCalendar
                  amount={amount}
                  currency={currency}
                  upfrontAmount={upfrontAmount}
                  interval={interval}
                  intervalCount={intervalCount}
                  totalCycleCount={cycleCount}
                  trialPeriodCount={trialPeriodCount}
                  trialPeriodInterval={trialPeriodInterval}
                />
              </div>
            </Spin>
          </div>
          <ProductFooter />
        </div>
      </motion.div>
    </div>
  );
};

export default SubsProductDetails;
