import React from "react";
import { Timeline, Tag } from "antd";
import {
  DollarCircleOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const todaysDate = dayjs();

const amountParser = (amount, currency) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  }).format(amount / 100);
};

const generateUpcomingPayments = ({
  amount,
  currency,
  interval,
  intervalCount,
  totalCycleCount,
  upfrontAmount,
  trialPeriodCount,
  trialPeriodInterval,
}) => {
  const events = [];
  const currentDate = todaysDate;
  let startDate = currentDate;

  const adjustedCycleCount = totalCycleCount - 1;

  if (upfrontAmount > 0) {
    events.push({
      date: currentDate,
      description: `${amountParser(upfrontAmount, currency)} will be charged`,
      type: "upfront",
      now: true,
    });
    startDate = currentDate.add(intervalCount, interval);
  } else if (trialPeriodCount > 0) {
    startDate = currentDate.add(trialPeriodCount, trialPeriodInterval);
    events.push({
      date: startDate,
      description: `Trial Ends, ${amountParser(
        amount,
        currency
      )} will be charged`,
      type: "trialEnd",
    });
    startDate = startDate.add(intervalCount, interval);
  } else {
    events.push({
      date: currentDate,
      description: `${amountParser(amount, currency)} will be charged`,
      type: "initialPayment",
      now: true,
    });
    startDate = currentDate.add(intervalCount, interval);
  }

  for (let i = 1; i <= adjustedCycleCount; i++) {
    const paymentDate = startDate.add((i - 1) * intervalCount, interval);
    events.push({
      date: paymentDate,
      description: `${amountParser(amount, currency)} will be charged`,
      type: "payment",
    });
  }

  const allUpcoming = events.filter(
    (e) => e.date.isAfter(currentDate) || e.date.isSame(currentDate, "day")
  );

  allUpcoming.sort((a, b) => a.date - b.date);

  return {
    upcoming: allUpcoming.slice(0, 3),
    totalCount: totalCycleCount,
  };
};

const SubsCalendar = (props) => {
  const {
    amount,
    currency,
    interval,
    intervalCount,
    totalCycleCount,
    upfrontAmount,
    trialPeriodCount,
    trialPeriodInterval,
  } = props;

  const { upcoming, totalCount } = generateUpcomingPayments({
    amount,
    currency,
    interval,
    intervalCount,
    totalCycleCount,
    upfrontAmount,
    trialPeriodCount,
    trialPeriodInterval,
  });

  const renderTimelineItem = (item, index) => {
    let color = "blue";
    let icon = <CalendarOutlined style={{ fontSize: "1.2em" }} />;
    switch (item.type) {
      case "upfront":
        color = "green";
        icon = <DollarCircleOutlined style={{ fontSize: "1.2em" }} />;
        break;
      case "initialPayment":
        color = "green";
        icon = <DollarCircleOutlined style={{ fontSize: "1.2em" }} />;
        break;
      case "trialEnd":
        color = "orange";
        icon = <ClockCircleOutlined style={{ fontSize: "1.2em" }} />;
        break;
      case "payment":
        color = "blue";
        icon = <DollarCircleOutlined style={{ fontSize: "1.2em" }} />;
        break;
      default:
        break;
    }

    return (
      <Timeline.Item key={index} color={color} dot={icon}>
        <div className="flex flex-col w-fit">
          <div className="flex gap-2 items-center pb-1">
            <div className="text-sm font-medium text-gray-800">
              {item.date.format("DD MMMM, YYYY")}
            </div>
            {item?.now && (
              <Tag
                color="green"
                style={{
                  padding: "0 2px",
                  fontSize: "9px",
                  lineHeight: "13px",
                }}
                size
              >
                NOW
              </Tag>
            )}
          </div>
          <span className="text-xs text-gray-600">{item.description}</span>
        </div>
      </Timeline.Item>
    );
  };

  return (
    <div className="mx-auto p-6 mt-4 max-w-xl">
      <Timeline mode="left">
        {upcoming.map((item, index) => renderTimelineItem(item, index))}
        {totalCount > upcoming.length && (
          <Timeline.Item
            dot={<CalendarOutlined style={{ fontSize: "1.2em" }} />}
            color="gray"
          >
            <div className="flex flex-col w-fit">
              <span className="text-sm font-medium text-gray-800">...</span>
              <span className="text-xs text-gray-600">
                {totalCount - upcoming.length} more payment
                {totalCount - upcoming.length > 1 ? "s" : ""}
              </span>
            </div>
          </Timeline.Item>
        )}
      </Timeline>
    </div>
  );
};

export default SubsCalendar;
