import { useSubscriptionContext } from "../../contexts/SubscriptionContext";

import useSubsPaymentConfirmation from "./useSubsPaymentConfirmation";

export const useSubsStripePayments = ({ setPayBtnLoading }) => {
  const {
    pg,
    stripePromise,
    stripeElement,
    setPaymentError,
    callbackUrl,
    currency,
    pgPublicKey,
    amount,
    paymentMethod,
    secret,
    stripeCardElement,
  } = useSubscriptionContext();

  const { subscriptionTriggerAck } = useSubsPaymentConfirmation();

  const ERROR_MESSAGE =
    "Your payment was declined by the payment method provider.";

  const generateReturnUrl = () => {
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set("pg", pg);
    returnUrl.searchParams.set("callbackUrl", callbackUrl);
    returnUrl.searchParams.set("paymentMethod", paymentMethod);
    returnUrl.searchParams.set("currency", currency);
    returnUrl.searchParams.set("pgPublicKey", pgPublicKey);
    returnUrl.searchParams.set("amount", amount);
    returnUrl.searchParams.set("secret", secret);

    return returnUrl.href;
  };

  const scrollToContainerTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const element = document.getElementById("payment");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      element.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    }
  };

  const subsStripeTokPay = async () => {
    const elements = stripeElement;
    if (!stripePromise || !elements) {
      return;
    }
    setPayBtnLoading(true);

    const { token, error } = await stripePromise.createToken(stripeCardElement);
    if (error) {
      scrollToContainerTop();
      if (error.type === "card_error" || error.type === "validation_error") {
        setPaymentError(error.message);
      } else {
        setPaymentError(ERROR_MESSAGE);
      }
    } else if (token) {
      if (token) {
        await subscriptionTriggerAck({ tokenId: token.id });
      }
    }
    setPayBtnLoading(false);
  };

  const subsStripePay = async () => {
    const elements = stripeElement;
    if (!stripePromise || !elements) {
      return;
    }
    setPayBtnLoading(true);
    const returnUrl = generateReturnUrl();

    const { error, setupIntent } = await stripePromise.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
      redirect: "if_required",
    });

    if (error) {
      scrollToContainerTop();
      if (error.type === "card_error" || error.type === "validation_error") {
        setPaymentError(error.message);
      } else {
        setPaymentError(ERROR_MESSAGE);
      }
    } else if (setupIntent) {
      if (setupIntent.status === "succeeded") {
        await subscriptionTriggerAck({ tokenId: null });
      } else {
        scrollToContainerTop();
        setPaymentError(ERROR_MESSAGE);
      }
    }

    setPayBtnLoading(false);
  };

  return { subsStripePay, subsStripeTokPay };
};
