/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form } from "antd";

import { PaymentMethod } from "../../../constants/enums/paymentMethod";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";

import ChargeCard from "./ChargeCard";

const AuthNetGateway = ({ paymentMethod }) => {
  const [form] = Form.useForm();
  const { setIsPaymentBtnDisabled, setForm } = useOneTimePaymentsContext();

  useEffect(() => {
    setIsPaymentBtnDisabled(false);
    setForm(form);
  }, []);

  const renderPg = () => {
    switch (paymentMethod) {
      case PaymentMethod.CARD:
        return <ChargeCard />;
      default:
        return <></>;
    }
  };

  return (
    <Form
      form={form}
      name={`authNetCheckout`}
      className="font-sans"
      layout="vertical"
      autoComplete="on"
      requiredMark={"optional"}
    >
      {renderPg()}
    </Form>
  );
};

export default AuthNetGateway;
