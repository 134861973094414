/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";

const OneTimePaymentsContext = createContext({});

export const OneTimePaymentsProvider = (props) => {
  const urlParam = new URLSearchParams(window.location.search);
  const [xIntentId, setXIntentId] = useState(urlParam.get("xpay_intent_id"));
  const [currency, setCurrency] = useState(urlParam.get("currency") || "USD");
  const [secret, setSecret] = useState(urlParam.get("secret"));
  const [amount, setAmount] = useState();
  const [country, setCountry] = useState();
  const [status, setStatus] = useState(null);
  const [mode, setMode] = useState();
  const [form, setForm] = useState();
  const [merchantName, setMerchantName] = useState();
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState();
  const [savePMDetails, setSavedPMDetails] = useState();
  const [savePMSelected, setSavePMSelected] = useState(false);

  const [loadingMain, setLoadingMain] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingMethods, setLoadingMethods] = useState(false);
  const [amountLoading, setAmountLoading] = useState(false);
  const [payPalBtnLoading, setPayPalBtnLoading] = useState(false);

  const [isPaymentBtnDisabled, setIsPaymentBtnDisabled] = useState(true);
  const [billingDetails, setBillingDetails] = useState();
  const [paymentError, setPaymentError] = useState(false);
  const [callbackUrl, setCallbackUrl] = useState();
  const [intentDetails, setIntentDetails] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [productDetails, setProductDetails] = useState();

  const [stripeElement, setStripeElement] = useState(); // Stripe
  const [stripePromise, setStripePromise] = useState(); // Stripe
  const [paymentRequest, setPaymentRequest] = useState(); // Stripe
  const [addressElement, setAddressElement] = useState(); // Stripe

  const [iframeUrl, setIframeUrl] = useState(null);
  const [isIframeModalOpen, setIsIframeModalOpen] = useState(false);
  const [isBillingDetailsIngested, setIsBillingDetailsIngested] =
    useState(false);

  return (
    <OneTimePaymentsContext.Provider
      value={{
        form,
        setForm,
        currency,
        setCurrency,
        amount,
        setAmount,
        xIntentId,
        setXIntentId,
        paymentMethodList,
        setPaymentMethodList,
        merchantName,
        setMerchantName,
        intentDetails,
        setIntentDetails,
        loading,
        setLoading,
        loadingMain,
        setLoadingMain,
        paymentError,
        setPaymentError,
        status,
        setStatus,
        mode,
        setMode,
        loadingMethods,
        setLoadingMethods,
        paymentMethod,
        setPaymentMethod,
        isPaymentBtnDisabled,
        setIsPaymentBtnDisabled,
        callbackUrl,
        setCallbackUrl,
        stripeElement,
        setStripeElement,
        stripePromise,
        setStripePromise,
        billingDetails,
        setBillingDetails,
        paymentRequest,
        setPaymentRequest,
        country,
        setCountry,
        addressElement,
        setAddressElement,
        iframeUrl,
        setIframeUrl,
        isIframeModalOpen,
        setIsIframeModalOpen,
        secret,
        setSecret,
        errorMessage,
        setErrorMessage,
        savePMDetails,
        setSavedPMDetails,
        savePMSelected,
        setSavePMSelected,
        isBillingDetailsIngested,
        setIsBillingDetailsIngested,
        productDetails,
        setProductDetails,
        amountLoading,
        setAmountLoading,
        payPalBtnLoading,
        setPayPalBtnLoading,
      }}
    >
      {props.children}
    </OneTimePaymentsContext.Provider>
  );
};

export const useOneTimePaymentsContext = () => {
  return useContext(OneTimePaymentsContext);
};
