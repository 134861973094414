/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import config from "../../../config";
import { STATUS } from "../../constants/enums/status";

const useTokyoWebSDKSetup = ({
  intentDetails,
  paymentMethod,
  amount,
  currency,
  secret,
  mode,
  setStatus,
  setPayPalBtnLoading,
  setPaymentError,
}) => {
  const generateReturnUrl = () => {
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set("pg", intentDetails.pg);
    returnUrl.searchParams.set("callbackUrl", intentDetails.callbackUrl);
    returnUrl.searchParams.set("paymentMethod", paymentMethod);
    returnUrl.searchParams.set("currency", currency);
    returnUrl.searchParams.set("pgPublicKey", intentDetails.pgPublicKey);
    returnUrl.searchParams.set("amount", amount);
    returnUrl.searchParams.set("secret", secret);
    returnUrl.searchParams.set("payment_reference_id", intentDetails.refId);
    returnUrl.searchParams.set("mode", mode);

    return returnUrl.href;
  };

  const cardType = [
    "VISA",
    "MA",
    "AXP",
    "DFS",
    "JCB",
    "Diners",
    "Hiper",
    "Banamex",
    "Banorte",
    "Bbva",
    "HSBC",
    "Santander",
    "CARNET",
  ];

  const tokyoSetup = async () => {
    setPayPalBtnLoading(true);

    let sdkEnv = config.tokyoEnv;
    let transaction_reference = intentDetails.refId;
    let access_token = intentDetails.pgPublicKey;
    let chargeToken = intentDetails.clientSecret;
    let citconInstance = null;
    let tokyoCallbackUrl = generateReturnUrl();
    let customerId = intentDetails.xIntentId + "-customer";
    let citconPaymentMethod = paymentMethod.toLowerCase();
    let tokyoIpn = config.tokyoIpn + `/${mode?.toLowerCase()}`;

    const configObj = {
      accessToken: access_token,
      environment: sdkEnv,
      debug: true,
      consumerID: customerId,
      cardTypes: cardType,
      urls: {
        ipn: tokyoIpn,
        success: tokyoCallbackUrl,
        fail: tokyoCallbackUrl,
        cancel: tokyoCallbackUrl,
      },
    };

    window.citconpay.client
      .core(configObj)
      .then((clientInstance) => {
        citconInstance = clientInstance;
        return clientInstance.mount(
          "#citcon-client-container",
          {
            classname: "payment-method-select-component",
            paymentMethods: [citconPaymentMethod],
            selectedPaymentMethod: [citconPaymentMethod],
          },
          sdkUIDidInitialized
        );
      })
      .then((instance) => {
        registerEvents();
      })
      .catch((error) => {
        console.log(error);
        setStatus(STATUS.ERROR);
      });

    function sdkUIDidInitialized(e) {}

    function registerEvents() {
      citconInstance.on("payment-method-selected", function (e) {
        let options = {
          consumer: {
            id: customerId,
            reference: customerId,
          },
          payment: {
            totalAmount: amount,
            currency: currency,
            countryCode: "US",
            transactionReference: transaction_reference,
            chargeToken: chargeToken,
            autoCapture: true,
          },
        };

        citconInstance
          .onPaymentMethodSelected(e.paymentMethod, options)
          .then((rest) => {
            setPayPalBtnLoading(false);
          })
          .catch((error) => {
            setPayPalBtnLoading(false);
            setPaymentError(error?.message);
          });
      });

      citconInstance.on("payment-method-submitted", function (e) {
        let rquestOptions = {
          consumer: {
            id: customerId,
            reference: customerId,
          },
          payment: {
            totalAmount: amount,
            currency: currency,
            countryCode: "US",
            transactionReference: transaction_reference,
            chargeToken: chargeToken,
            autoCapture: true,
          },
        };

        citconInstance
          .onPaymentMethodSubmitted(e.paymentMethod, rquestOptions)
          .then((rest) => {})
          .catch((error) => {
            setPaymentError(error?.message);
          });
      });

      citconInstance.on("payment-status-changed", function (e) {
        const status = e.status;
        const res = e.data;
        if (status === "success") {
          setStatus(STATUS.SUCCESS);
        } else {
          setPaymentError(res.message);
        }
      });
    }
  };

  useEffect(() => {
    tokyoSetup();
  }, []);
};

export default useTokyoWebSDKSetup;
