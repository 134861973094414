import React from "react";
import { useSubscriptionContext } from "../../../contexts/SubscriptionContext";
// import ChargeCard from "./../ChargeCard";
import TestCardsContainer from "../../TestMode/TestCardsContainer";
import PaymentError from "../../UI/PaymentError";
import SubsStripeGateway from "../StripeGateway/SubsStripeGateway";

import { PaymentMethod } from "../../../constants/enums/paymentMethod";
import { PG } from "../../../constants/enums/pg";
import { Mode } from "../../../constants/enums/mode";
import SubsStripeTokGateway from "../StripeGateway/SubsStripeTokGateway";

const SubsPayment = () => {
  const { pg, paymentError, mode, paymentMethod } = useSubscriptionContext();

  const renderPg = () => {
    switch (pg) {
      // case "AUTHNET":
      //   return <ChargeCard />;
      case PG.STRIPE:
        return <SubsStripeGateway />;
      case PG.STRIPE_TOK:
        return <SubsStripeTokGateway />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`overflow-y-auto overflow-x-hidden sm:h-[calc(100vh-473px)] sm:px-2 flex flex-col gap-4 relative`}
      id="payment"
    >
      {paymentError && <PaymentError paymentError={paymentError} />}
      {renderPg()}
      {mode === Mode.TEST && paymentMethod === PaymentMethod.CARD && (
        <TestCardsContainer pg={pg} />
      )}
    </div>
  );
};

export default SubsPayment;
