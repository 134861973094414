/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form } from "antd";
import { cardBrand } from "../../constants/cards";
import { MdKeyboardArrowRight } from "react-icons/md";

const RecentPMs = ({
  savedPMDetails,
  setSavePMDrawerOpen,
  setSavePMSelected,
  setForm,
  setManageSavedPMDrawerOpen,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    setForm(form);
  }, []);

  const onRecentlyUsedSelected = (value) => {
    setSavePMSelected(value);
    setSavePMDrawerOpen(true);
  };

  const handleOther = () => {
    setManageSavedPMDrawerOpen(true);
  };

  return (
    <div
      className="flex items-center justify-between gap-4 p-4 mb-2 border border-gray-200 rounded-lg"
      id="recentPMs"
    >
      <div className="flex items-center gap-4">
        {savedPMDetails?.slice(0, 1).map((savedPM) => (
          <div
            className="flex items-center justify-between gap-4 p-2 border rounded-lg cursor-pointer bg-gray-50 hover:border-blue-400 drop-shadow-sm"
            onClick={() => onRecentlyUsedSelected(savedPM)}
            key={savedPM?.pmId}
          >
            <div className="h-fit">
              {cardBrand(savedPM?.cardDetails?.brand?.toLowerCase())}
            </div>
            <div className="flex items-center gap-2">
              <span>**** {savedPM?.cardDetails?.lastFourDigits}</span>
              <MdKeyboardArrowRight />
            </div>
          </div>
        ))}
      </div>
      <div
        className="flex items-center text-sm underline cursor-pointer hover:text-blue-500"
        onClick={handleOther}
      >
        Others
        <MdKeyboardArrowRight className="mt-1"/>
      </div>
      <div className="absolute top-[-10px] text-sm left-5 bg-white px-2">
        Pay via
      </div>
    </div>
  );
};

export default RecentPMs;
