import React, { useState } from "react";
import { cardBrand } from "../../constants/cards";
import { MdDeleteOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { Tooltip, Modal, Button, Empty } from "antd";
import { useDeleteSavedPM } from "../../hooks/OneTimePayment/useDeleteSavedPM";
import { ReactComponent as UserTick } from "../../assets/Icons/Check-filled.svg";
import { useChargeToken } from "../../hooks/OneTimePayment/useChargeToken";
import { FaRegCircle } from "react-icons/fa";
import PaymentError from "../UI/PaymentError";

const ManageSavedPM = ({
  savePMDetails,
  onManagePMDrawerClose,
  paymentError,
  savePMSelected,
  setSavePMSelected,
}) => {
  const { deleteSavedPM, loading } = useDeleteSavedPM();
  const [open, setOpen] = useState(false);
  const { chargeToken, loading: payBtnLoading } = useChargeToken();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletePM = async () => {
    await deleteSavedPM({ pmId: savePMSelected.pmId, onManagePMDrawerClose });
    handleClose();
  };

  const handleSavePM = (pm) => {
    setSavePMSelected(pm);
  };

  const onPayClick = async () => {
    await chargeToken({
      savePMSelected,
      onSavePMDrawerClose: onManagePMDrawerClose,
    });
  };

  return (
    <div className="flex flex-col gap-5 font-sans sm:py-3 sm:px-6 z-[1000]">
      <div className="flex items-center justify-between gap-2 text-xl font-medium">
        Saved Details
        <IoCloseOutline
          size={20}
          className="cursor-pointer"
          onClick={onManagePMDrawerClose}
        />
      </div>
      {(savePMDetails && savePMDetails.length) > 0 ? (
        <div>
          {savePMDetails?.map((pm, index) => (
            <div
              className={`relative p-3 my-4 border-2 ${
                savePMSelected === pm ? "border-blue-500" : "border-gray-200"
              } rounded-lg shadow-sm cursor-pointer`}
              key={index}
              onClick={() => handleSavePM(pm)}
            >
              <div className="flex items-center justify-between gap-4">
                {/* Card Brand & Details */}

                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    {savePMSelected === pm ? <UserTick /> : <FaRegCircle />}
                    <div className="h-fit">
                      {cardBrand(pm?.cardDetails?.brand?.toLowerCase())}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <span>**** {pm?.cardDetails?.lastFourDigits}</span>

                    {pm?.cardDetails?.expiryMonth &&
                      pm?.cardDetails?.expiryYear && (
                        <>
                          <div className="h-5 mx-2 border-l border-gray-300" />
                          <div className="flex justify-end text-xs text-right">
                            {pm?.cardDetails?.expiryMonth} /{" "}
                            {pm?.cardDetails?.expiryYear}
                          </div>
                        </>
                      )}
                  </div>
                </div>

                {/* Delete */}
                <div
                  className="flex items-center gap-1 text-red-500 cursor-pointer"
                  onClick={handleClickOpen}
                >
                  <div className="h-5 mx-2 border-l border-gray-300" />
                  <Tooltip title="Delete" placement="left">
                    <MdDeleteOutline size={20} />
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}
          {paymentError && <PaymentError paymentError={paymentError} />}
          <Button
            size="large"
            className="font-sans font-semibold mt-5"
            block
            htmlType="submit"
            loading={payBtnLoading}
            onClick={() => onPayClick()}
            disabled={!savePMDetails.find((pm) => pm === savePMSelected)}
          >
            Pay
          </Button>
        </div>
      ) : (
        <div>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No saved details found!"
          />
        </div>
      )}

      <Modal
        open={open}
        onCancel={handleClose}
        footer={null}
        centered
        closable={false}
        width={328}
        confirmLoading={loading}
      >
        <div className="flex flex-col items-center justify-center gap-6 py-1">
          <div className="text-red-500 bg-[#FDE8E8] p-4 w-fit rounded-full">
            <MdDeleteOutline size={36} />
          </div>
          <div className="font-sans text-lg font-normal text-center">
            Are you sure you want to delete this payment method? <br />
          </div>
          <div className="grid w-full grid-cols-2 gap-2 mt-4">
            <Button
              className="w-full text-black bg-[#E6E6E6] font-sans"
              size="large"
              onClick={handleDeletePM}
              loading={loading}
            >
              Yes
            </Button>
            <Button
              className="w-full font-sans text-white bg-black"
              size="large"
              onClick={handleClose}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ManageSavedPM;
