import SubscriptionStatusSuccess from "../StatusScreens/SubscriptionStatusSuccess";
import PaymentStatusError from "../StatusScreens/PaymentStatusError";
import useSubsPaymentConfirmation from "../../hooks/Subscription/useSubsPaymentConfirmation";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import SubscriptionFailedRetry from "../StatusScreens/SubscriptionFailedRetry";
import { useEffect } from "react";
import { SUBS_STATUS } from "../../constants/enums/subsStatus";
import TerminalState from "../StatusScreens/TerminalState";

const SubsPaymentStatusView = ({ status, errorMessage, isUpdate = false }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { subsTriggerRedirect } = useSubsPaymentConfirmation();
  const { loadingMain, subscriptionId } = useSubscriptionContext();

  switch (status) {
    case SUBS_STATUS.COMPLETED:
    case SUBS_STATUS.ENDED:
    case SUBS_STATUS.ACTIVE:
    case SUBS_STATUS.TRIALING:
      return (
        <SubscriptionStatusSuccess
          triggerRedirect={subsTriggerRedirect}
          loadingMain={loadingMain}
        />
      );
    case SUBS_STATUS.CREATED:
    case SUBS_STATUS.INCOMPLETE:
    case SUBS_STATUS.INACTIVE:
    case SUBS_STATUS.UNPAID:
      return (
        <SubscriptionFailedRetry message={errorMessage} update={isUpdate} />
      );
    case SUBS_STATUS.TERMINAL_STATE:
      return <TerminalState loadingMain={loadingMain} />;
    default:
      return <PaymentStatusError id={subscriptionId} />;
  }
};

export default SubsPaymentStatusView;
