import React from "react";
import ProductDetailsCard from "../UI/ProductDetailsCard";
import ProductAmount from "./ProductAmount";
import { motion } from "framer-motion";
import ProductFooter from "../UI/ProductFooter";

const ProductDetails = ({
  merchantLogo,
  productDetails,
  amount,
  currency,
  amountLoading,
  merchantName,
}) => {
  return (
    <div
      className="bg-white sm:pb-9 sm:pt-28 sm:pl-12 pt-8 pl-6 relative h-full w-full max-w-[46rem] sm:pr-[128px] hidden lg:block border-l-[1px] border-gray-300"
      id="container"
    >
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className="h-full flex flex-col justify-between"
      >
        <div>
          <ProductDetailsCard
            merchantLogo={merchantLogo}
            productDetails={productDetails}
          />
          <ProductAmount
            merchantName={merchantName}
            amountLoading={amountLoading}
            currency={currency}
            amount={amount}
          />
          <div className="pt-4">
            <ProductFooter />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ProductDetails;
