import React from "react";
import { Button } from "antd";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import useUpdateSubsPaymentMethod from "../../hooks/UpdateSubsPM/useUpdateSubsPaymentMethod";

const SubsUpdatePMButton = () => {
  const { subsTriggerUpdate, payBtnLoading } = useUpdateSubsPaymentMethod();
  const { isPaymentBtnDisabled } = useSubscriptionContext();

  const handleUpdatePM = async () => {
    await subsTriggerUpdate();
  };

  return (
    <div className="mt-4 sm:mt-6">
      <Button
        size="large"
        className="font-sans font-semibold"
        block
        onClick={handleUpdatePM}
        loading={payBtnLoading}
        disabled={isPaymentBtnDisabled}
      >
        Update
      </Button>
    </div>
  );
};

export default SubsUpdatePMButton;
