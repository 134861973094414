import { useState } from "react";

import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { PG } from "../../constants/enums/pg";

import { useUpdateSubsStripePM } from "./useUpdateSubsStripePM";

const useUpdateSubsPaymentMethod = () => {
  const { pg } = useSubscriptionContext();
  const [payBtnLoading, setPayBtnLoading] = useState(false);
  const { subsStripeUpdate, subsStripeTokUpdate } = useUpdateSubsStripePM({
    setPayBtnLoading,
  });

  const subsTriggerUpdate = async () => {
    switch (pg) {
      case PG.STRIPE:
        await subsStripeUpdate();
        break;
      case PG.STRIPE_TOK:
        await subsStripeTokUpdate();
        break;
      default:
        break;
    }
  };

  return { subsTriggerUpdate, payBtnLoading };
};

export default useUpdateSubsPaymentMethod;
