/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../../axios";
import { fieldNames } from "../../constants/fieldNames";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { Country } from "country-state-city";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { useEffect, useState } from "react";
import useGetPaymentMethod from "../../hooks/OneTimePayment/usePaymentMethod";
import { sendEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/enums/eventNames";
import { useHandleAsyncPM } from "./useHandleAsyncPM";
import {
  getCurrencyCountryName,
  getCurrencyName,
  getCurrencySymbol,
} from "../../utils/IntlHelper";
const usePrefetchCurrencies = () => {
  const {
    xIntentId,
    setCurrency,
    setStatus,
    merchantName,
    setMerchantName,
    setLoading,
    setLoadingMain,
    setMode,
    setAmount,
    setCallbackUrl,
    billingDetails,
    setBillingDetails,
    setCountry,
    productDetails,
    setProductDetails,
  } = useOneTimePaymentsContext();

  const [currenciesOptionsList, setCurrenciesOptionsList] = useState([]);

  const { getPaymentMethod, paymentMethodList } = useGetPaymentMethod();
  const { handleAsyncPM } = useHandleAsyncPM();

  const urlParam = new URLSearchParams(window.location.search);
  const pg = urlParam.get("pg");

  // Setters
  const setDropdownCurrencies = (data) => {
    let currenciesOptionsList = [];
    currenciesOptionsList.push({
      label: getCurrencySymbol(data.primary.currency),
      value: data.primary.currency,
      searchValue:
        getCurrencyCountryName(data.primary.currency) +
        " - " +
        getCurrencyName(data.primary.currency) +
        " ",
    });

    data.others.forEach((element) => {
      currenciesOptionsList.push({
        label: getCurrencySymbol(element),
        value: element,
        searchValue:
          getCurrencyCountryName(element) +
          " - " +
          getCurrencyName(element) +
          " ",
      });
    });

    setCurrenciesOptionsList(currenciesOptionsList);
  };

  const setData = (data) => {
    if (!pg) {
      setAmount(data?.primary?.amount);
      setCurrency(data?.primary?.currency);
    }
    setMerchantName(data?.merchantName);
    setMode(data?.mode);
    setCallbackUrl(data?.callbackUrl);
    setCountry(data?.country);
    setDropdownCurrencies(data);
    setProductDetails(data?.productPage);
  };

  // Handlers
  const handleBillingDetails = async (data) => {
    setBillingDetails({
      ...billingDetails,
      [fieldNames.name]: data?.name,
      [fieldNames.email]: data?.email,
      [fieldNames.pincode]: data?.pinCode,
      [fieldNames.country]: data?.country
        ? data?.country + "-" + Country.getCountryByCode(data?.country)?.name
        : null,
    });
  };

  // Main
  useEffect(() => {
    const getPrefetchCurrencies = async () => {
      setLoadingMain(true);
      try {
        const isSuccess = await handleAsyncPM({ pg });
        if (isSuccess) return;
        const { data } = await axios.post(
          `/payments/v2/prefetchCurrencies/${xIntentId}`
        );
        setData(data);
        await handleBillingDetails(data);
        await getPaymentMethod(
          urlParam.get("currency") || data?.primary?.currency,
          data
        );

        if (!pg) {
          sendEvent(xIntentId, EVENT_NAMES.CHECKOUT_OPEN, {});
        }
        setLoading(false);
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoadingMain(false);
      }
    };

    getPrefetchCurrencies();
  }, []);

  return {
    merchantName,
    currenciesOptionsList,
    billingDetails,
    setBillingDetails,
    getPaymentMethod,
    paymentMethodList,
    productDetails,
  };
};

export default usePrefetchCurrencies;
