/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../UI/Header.jsx";
import ProceedButton from "./ProceedButton.jsx";
import TransDetails from "./TransDetails.jsx";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext.jsx";
import { Spin } from "antd";
import { ImSpinner2 } from "react-icons/im";
import PaymentStatusView from "./PaymentStatusView.jsx";
import useTriggerIntent from "../../hooks/OneTimePayment/useTriggerIntent.js";
import UserBox from "./UserBox.jsx";
import { Drawer } from "antd";
import useCalculateAmount from "../../hooks/OneTimePayment/useCalculateAmount";
import CustomCurrencyField from "../UI/CustomCurrencyField";
import Method from "./Steps/Method.jsx";
import YourDetails from "./Steps/YourDetails.jsx";
import Payment from "./Steps/Payment";
import { toast } from "react-hot-toast";
import PaymentDetails from "../UI/PaymentDetails.jsx";
import WarningToast from "./../UI/Toasts/WarningToast";
import SavedPMCheckout from "./Steps/SavedPMCheckout.jsx";
import ManageSavedPM from "./ManageSavedPM.jsx";

const Container = ({
  merchantName,
  currenciesOptionsList,
  billingDetails,
  setBillingDetails,
  getPaymentMethod,
  paymentMethodList,
  merchantLogo,
}) => {
  const {
    xIntentId,
    loading,
    status,
    intentDetails,
    setIntentDetails,
    loadingMain,
    setLoadingMain,
    mode,
    loadingMethods,
    paymentMethod,
    setPaymentMethod,
    currency,
    setCurrency,
    setPaymentError,
    setIsPaymentBtnDisabled,
    callbackUrl,
    paymentRequest,
    country,
    errorMessage,
    savePMDetails,
    savePMSelected,
    setSavePMSelected,
    paymentError,
    form,
    setForm,
    isBillingDetailsIngested,
    productDetails,
  } = useOneTimePaymentsContext();
  const { triggerIntent } = useTriggerIntent();
  const [open, setOpen] = useState(false);
  const [savePMDrawerOpen, setSavePMDrawerOpen] = useState(false);
  const [manageSavedPMDrawerOpen, setManageSavedPMDrawerOpen] = useState(false);

  useEffect(() => {
    if (
      (!billingDetails?.name ||
        !billingDetails?.email ||
        !billingDetails?.country) &&
      !loading
    ) {
      setOpen(true);
    }
  }, [loading]);

  const onClose = () => {
    if (
      billingDetails?.name &&
      billingDetails?.email &&
      billingDetails?.country
    ) {
      setOpen(false);
    } else {
      toast.custom(
        (t) => (
          <WarningToast
            t={t}
            message="Please fill all the details. We collect this information to help combat fraud, and to keep your payment secure."
          />
        ),
        {
          id: "userDetails",
        }
      );
    }
  };

  const onSavePMDrawerClose = () => {
    setSavePMDrawerOpen(false);
    setPaymentError(null);
    form.resetFields();
  };

  const onManagePMDrawerClose = () => {
    setManageSavedPMDrawerOpen(false);
  };

  const { amountLoading, getCalculatedAmount } = useCalculateAmount();

  const isMobile = typeof window !== "undefined" && window.innerWidth < 512;

  const renderContent = () => {
    if (status) {
      return <PaymentStatusView status={status} errorMessage={errorMessage} />;
    }

    return (
      <Spin
        spinning={loading}
        indicator={<ImSpinner2 className="animate-spin" />}
        size="large"
        className=""
      >
        <div className="flex flex-col w-full h-full gap-7">
          {/* User details Sections */}
          {billingDetails?.name &&
            billingDetails?.email &&
            billingDetails?.country && (
              <UserBox
                open={open}
                setOpen={setOpen}
                billingDetails={billingDetails}
                productDetails={productDetails}
              />
            )}

          {/* Payment Details Sections */}
          <div className="flex items-center justify-between w-full gap-3">
            <PaymentDetails
              intentDetails={intentDetails}
              setIntentDetails={setIntentDetails}
              paymentMethod={paymentMethod}
              paymentMethodList={paymentMethodList}
              setPaymentError={setPaymentError}
              setIsPaymentBtnDisabled={setIsPaymentBtnDisabled}
              savedPMDetails={savePMDetails}
              setPaymentMethod={setPaymentMethod}
            />
            <CustomCurrencyField
              currency={currency}
              setCurrency={setCurrency}
              currenciesOptionsList={currenciesOptionsList}
              getCalculatedAmount={getCalculatedAmount}
              getPaymentMethod={getPaymentMethod}
              setIntentDetails={setIntentDetails}
              setPaymentError={setPaymentError}
              setIsPaymentBtnDisabled={setIsPaymentBtnDisabled}
              country={country}
              xIntentId={xIntentId}
            />
          </div>

          {/* Payment Body */}
          {!intentDetails ? (
            <Method
              paymentMethodList={paymentMethodList}
              setPaymentMethod={setPaymentMethod}
              loading={loadingMethods}
              triggerIntent={triggerIntent}
              currency={currency}
              paymentRequest={paymentRequest}
              xIntentId={xIntentId}
              savedPMDetails={savePMDetails}
              setSavePMDrawerOpen={setSavePMDrawerOpen}
              setSavePMSelected={setSavePMSelected}
              setForm={setForm}
              setManageSavedPMDrawerOpen={setManageSavedPMDrawerOpen}
              billingDetails={billingDetails}
              isBillingDetailsIngested={isBillingDetailsIngested}
            />
          ) : (
            <Payment />
          )}
        </div>

        {/* Footer Sections */}
        {!isMobile && (
          <div className="fixed sm:w-[416px] bottom-9 hidden sm:block bg-white">
            <TransDetails
              merchantName={merchantName}
              currenciesOptionsList={currenciesOptionsList}
              amountLoading={amountLoading}
            />
            <ProceedButton />
          </div>
        )}
      </Spin>
    );
  };

  return (
    <>
      <div
        className="bg-white h-full w-full max-w-[32rem] sm:drop-shadow-2xl sm:rounded-2xl sm:py-9 sm:px-12 pt-8 pb-[137px] px-6 sm:backdrop-blur sm:shadow-2xl overflow-y-auto relative"
        id="container"
      >
        <Spin
          spinning={loadingMain}
          indicator={<ImSpinner2 className="animate-spin" />}
          size="large"
        >
          {/* Header */}
          <Header
            setLoadingMain={setLoadingMain}
            mode={mode}
            merchantLogo={merchantLogo}
            callbackUrl={callbackUrl}
            xIntentId={xIntentId}
            productDetails={productDetails}
          />
          {renderContent()}
        </Spin>

        {/* User Details Drawer */}
        <Drawer
          onClose={onClose}
          closable={false}
          open={open && !status}
          placement="bottom"
          getContainer={false}
          autoFocus={false}
          className="rounded-t-xl"
          rootClassName="hidden sm:block"
          height="full"
        >
          <YourDetails
            setOpen={setOpen}
            billingDetails={billingDetails}
            setBillingDetails={setBillingDetails}
            xIntentId={xIntentId}
            isBillingDetailsIngested={isBillingDetailsIngested}
          />
        </Drawer>

        <Drawer
          onClose={onSavePMDrawerClose}
          closable={false}
          open={savePMDrawerOpen}
          placement="bottom"
          getContainer={false}
          autoFocus={false}
          className="rounded-t-xl"
          rootClassName="hidden sm:block"
          height="full"
        >
          <SavedPMCheckout
            onSavePMDrawerClose={onSavePMDrawerClose}
            savePMSelected={savePMSelected}
            paymentError={paymentError}
          />
        </Drawer>

        <Drawer
          onClose={onManagePMDrawerClose}
          closable={false}
          open={manageSavedPMDrawerOpen}
          placement="bottom"
          getContainer={false}
          autoFocus={false}
          className="rounded-t-xl"
          rootClassName="hidden sm:block"
          height="full"
        >
          <ManageSavedPM
            savePMDetails={savePMDetails}
            onManagePMDrawerClose={onManagePMDrawerClose}
            paymentError={paymentError}
            savePMSelected={savePMSelected}
            setSavePMSelected={setSavePMSelected}
          />
        </Drawer>
      </div>

      {/* Mobile Views */}
      <div
        className={`fixed w-full sm:w-[416px] bottom-0 pb-6 px-6 sm:hidden bg-white ${
          !status && !loading ? "block" : "hidden"
        }`}
      >
        <TransDetails
          merchantName={merchantName}
          currenciesOptionsList={currenciesOptionsList}
          amountLoading={amountLoading}
        />
        <ProceedButton />
      </div>
      <Drawer
        onClose={onClose}
        closable={false}
        open={open && !status}
        placement="bottom"
        autoFocus={false}
        className="rounded-t-xl"
        rootClassName="sm:hidden"
        height="full"
      >
        <YourDetails
          setOpen={setOpen}
          billingDetails={billingDetails}
          setBillingDetails={setBillingDetails}
          xIntentId={xIntentId}
          isBillingDetailsIngested={isBillingDetailsIngested}
        />
      </Drawer>
      <Drawer
        onClose={onSavePMDrawerClose}
        closable={false}
        open={savePMDrawerOpen}
        placement="bottom"
        getContainer={false}
        autoFocus={false}
        className="rounded-t-xl"
        rootClassName="sm:hidden"
        height="full"
      >
        <SavedPMCheckout
          onSavePMDrawerClose={onSavePMDrawerClose}
          savePMSelected={savePMSelected}
          paymentError={paymentError}
        />
      </Drawer>
      <Drawer
        onClose={onManagePMDrawerClose}
        closable={false}
        open={manageSavedPMDrawerOpen}
        placement="bottom"
        getContainer={false}
        autoFocus={false}
        className="rounded-t-xl"
        rootClassName="sm:hidden"
        height="full"
      >
        <ManageSavedPM
          savePMDetails={savePMDetails}
          onManagePMDrawerClose={onManagePMDrawerClose}
        />
      </Drawer>
    </>
  );
};

export default Container;
