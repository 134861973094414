import { useState } from "react";

import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { PG } from "../../constants/enums/pg";

import { useSubsStripePayments } from "./useSubsStripePayments";

const useSubsTriggerPayment = () => {
  const { pg } = useSubscriptionContext();
  const [payBtnLoading, setPayBtnLoading] = useState(false);
  const { subsStripePay, subsStripeTokPay } = useSubsStripePayments({ setPayBtnLoading });

  const subsTriggerPayment = async () => {
    switch (pg) {
      case PG.STRIPE:
        await subsStripePay();
        break;
      case PG.STRIPE_TOK:
        await subsStripeTokPay();
        break;
      default:
        break;
    }
  };

  return { subsTriggerPayment, payBtnLoading };
};

export default useSubsTriggerPayment;
