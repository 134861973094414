import React from "react";
import { Tooltip } from "antd";

// Icons
import { ReactComponent as UserTick } from "../../assets/Icons/Check-filled.svg";

const SubsStaticUserBox = ({ setOpen, billingDetails, productDetails }) => {
  const maxCharacters =
    window.innerWidth > 512 ? 40 : (window.innerWidth - 176) / 8;

  const isMobileDevice =
    typeof window !== "undefined" && window.innerWidth < 1024;

  return (
    <div>
      {productDetails && (
        <div className="pb-7 text-lg font-medium flex gap-4 items-center lg:hidden justify-center">
          <div className="flex items-center gap-2 flex-col">
            <Tooltip title={productDetails?.name} placement="top">
              <span className="truncate max-w-[25ch]">
                {productDetails?.name}
              </span>
            </Tooltip>
            <Tooltip title={productDetails?.description} placement="top">
              <span className="line-clamp-2 text-xs text-[#535353] text-center">
                {productDetails?.description} 
              </span>
            </Tooltip>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between gap-2 px-4 py-2 border border-gray-200 rounded-lg sm:p-4">
        <div>
          <div className="flex items-center gap-2 font-medium">
            <span>
              {billingDetails?.name?.length > maxCharacters
                ? billingDetails?.name?.slice(0, maxCharacters - 5) + "..."
                : billingDetails?.name}
            </span>{" "}
            <UserTick />
          </div>

          {isMobileDevice ? (
            <>
              {!productDetails && (
                <div className="text-[#535353] font-normal">
                  {billingDetails?.email?.length +
                    billingDetails?.country?.length >
                  maxCharacters ? (
                    <div>
                      {billingDetails?.email?.length > maxCharacters
                        ? billingDetails?.email?.slice(0, maxCharacters) + "..."
                        : billingDetails?.email}
                      <br />
                      {billingDetails?.country?.length > maxCharacters
                        ? `${billingDetails?.country
                            ?.split("-")[1]
                            ?.slice(0, maxCharacters)}...`
                        : billingDetails?.country?.split("-")[1]}
                    </div>
                  ) : (
                    `${billingDetails?.email} • ${
                      billingDetails?.country?.split("-")[1]
                    }`
                  )}
                </div>
              )}{" "}
            </>
          ) : (
            <div className="text-[#535353] font-normal">
              {billingDetails?.email?.length + billingDetails?.country?.length >
              maxCharacters ? (
                <div>
                  {billingDetails?.email?.length > maxCharacters
                    ? billingDetails?.email?.slice(0, maxCharacters) + "..."
                    : billingDetails?.email}
                  <br />
                  {billingDetails?.country?.length > maxCharacters
                    ? `${billingDetails?.country
                        ?.split("-")[1]
                        ?.slice(0, maxCharacters)}...`
                    : billingDetails?.country?.split("-")[1]}
                </div>
              ) : (
                `${billingDetails?.email} • ${
                  billingDetails?.country?.split("-")[1]
                }`
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubsStaticUserBox;
