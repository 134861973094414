import axios from "../../axios";

import { PaymentMethod } from "../../constants/enums/paymentMethod";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { ErrorHandler } from "./../../utils/ErrorHandler";
import { STATUS } from "./../../constants/enums/status";

export const useAuthnet = ({ setPayBtnLoading }) => {
  const {
    xIntentId,
    setPaymentError,
    setStatus,
    paymentMethod,
    form,
    setIframeUrl,
    setIsIframeModalOpen,
  } = useOneTimePaymentsContext();

  const handleStatus = (data, isIframe) => {
    if (data?.status === STATUS.SUCCESS) {
      setStatus(STATUS.SUCCESS);
    } else if (data?.redirectUrl) {
      if (!isIframe) {
        window.location.replace(data.redirectUrl);
      } else {
        setIframeUrl(data.redirectUrl);
        setIsIframeModalOpen(true);
        setPayBtnLoading(false);
      }
    } else {
      setPaymentError(data?.errorMessage);
      setPayBtnLoading(false);
    }
  };

  const authNetCardPayload = (values) => {
    const cardValidity = values.cardValidity.split(" / ");
    const cardNumber = values.cardNumber.replace(/\s/g, "");
    const cardDetails = {
      cardNumber: cardNumber,
      cardHolderName: values.cardName,
      expiryMonth: cardValidity[0],
      expiryYear: cardValidity[1],
      cardCode: values.cardSecurityCode,
    };

    return {
      xIntentId: xIntentId,
      cardDetails: cardDetails,
    };
  };

  const getRequestPayload = ({ paymentMethod, values }) => {
    switch (paymentMethod) {
      case PaymentMethod.CARD:
        return authNetCardPayload(values);
      default:
        return {};
    }
  };

  const isIframe = ({ paymentMethod }) => {
    return paymentMethod === PaymentMethod.CARD;
  };

  const authNetChargeCard = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setPayBtnLoading(true);
        const payload = getRequestPayload({
          paymentMethod,
          values,
        });
        try {
          const { data } = await axios.post(`/payments/charge-card`, payload);
          handleStatus(data, isIframe({ paymentMethod }));
        } catch (error) {
          setPayBtnLoading(false);
          ErrorHandler(error, setStatus);
        }
      })
      .catch((error) => {
        setPayBtnLoading(false);
        setPaymentError("Please fill in all required fields.");

        const fieldElement = document.querySelector(
          `[name="${error.errorFields[0].name}"]`
        );
        if (fieldElement) {
          fieldElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      });
  };

  return { authNetChargeCard };
};