import React from "react";

const SubsTnxDetails = ({
  amount,
  currency,
  interval,
  intervalCount,
  totalCycleCount,
  upfrontAmount,
  trialPeriodCount,
  trialPeriodInterval,
}) => {
  const amountParser = (amount, currency) => {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    }).format(amount / 100);
  };

  const intervalParser = (interval) => {
    switch (interval) {
      case "DAY":
        return "daily";
      case "WEEK":
        return "weekly";
      case "MONTH":
        return "monthly";
      case "YEAR":
        return "yearly";
      default:
        return interval;
    }
  };

  const mainAmount = () => {
    if (trialPeriodCount) {
      return (
        <>
          {trialPeriodCount} {trialPeriodInterval.toLowerCase()}
          {trialPeriodCount > 1 ? "s" : ""} trial
        </>
      );
    } else if (upfrontAmount) {
      return (
        <>
          <span className="font-semibold">
            {amountParser(upfrontAmount, currency)}
          </span>{" "}
          now
        </>
      );
    } else {
      return (
        <>
          <span className="font-semibold">
            {amountParser(amount, currency)}
          </span>{" "}
          now
        </>
      );
    }
  };

  const intervalDisplay = () => {
    if (intervalCount === 1) {
      return <>{intervalParser(interval)}</>;
    } else {
      return (
        <>
          every {intervalCount} {interval?.toLowerCase()}
          {intervalCount > 1 ? "s" : ""}
        </>
      );
    }
  };

  const totalCycleDisplay = () => {
    if (trialPeriodCount) {
      return totalCycleCount;
    } else {
      return totalCycleCount - 1;
    }
  };

  return (
    <div>
      <div>{mainAmount()},</div>
      <div className="text-[#535353] text-xs">
        then {amountParser(amount, currency)} {intervalDisplay()},
      </div>
      <div className="text-[#535353] text-xs">
        for the next {totalCycleDisplay()} payment
        {totalCycleDisplay() > 1 ? "s" : ""}.{" "}
      </div>
    </div>
  );
};

export default SubsTnxDetails;
