/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Divider, Modal } from "antd";
import { ImSpinner2 } from "react-icons/im";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import SubsTnxDetails from "../UI/SubsTnxDetails";
import SubsCalendar from "../UI/SubsCalendar";

const TransDetails = ({ merchantName, amountLoading }) => {
  const {
    currency,
    amount,
    upfrontAmount,
    interval,
    intervalCount,
    cycleCount,
    trialPeriodCount,
    trialPeriodInterval,
  } = useSubscriptionContext();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Divider className="my-4 sm:my-6" />
      <div className="flex justify-between gap-2">
        <div className="flex flex-col gap-1">
          <div>
            <span className="">Paying to</span>{" "}
            <span className="font-semibold">{merchantName}</span>
          </div>
          <div
            className="text-xs text-indigo-500 underline cursor-pointer"
            onClick={handleClickOpen}
          >
            View subscription calendar
          </div>
          <Modal
            open={open}
            onCancel={handleClose}
            footer={null}
            centered
            closable={true}
            title={"Subscription Details"}
            width={400}
          >
            <SubsCalendar
              amount={amount}
              currency={currency}
              upfrontAmount={upfrontAmount}
              interval={interval}
              intervalCount={intervalCount}
              totalCycleCount={cycleCount}
              trialPeriodCount={trialPeriodCount}
              trialPeriodInterval={trialPeriodInterval}
            />
          </Modal>
        </div>
        <div className="text-right">
          {amountLoading ? (
            <div className="flex items-center justify-center text-sm">
              <ImSpinner2 className="mr-2 animate-spin" />
              <span className="animate-pulse">Calculating...</span>
            </div>
          ) : (
            <SubsTnxDetails
              amount={amount}
              currency={currency}
              upfrontAmount={upfrontAmount}
              interval={interval}
              intervalCount={intervalCount}
              totalCycleCount={cycleCount}
              trialPeriodCount={trialPeriodCount}
              trialPeriodInterval={trialPeriodInterval}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TransDetails;
