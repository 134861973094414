import React from "react";
import { Button } from "antd";
import { IoCloseOutline } from "react-icons/io5";
import { cardBrand } from "../../../constants/cards";
import { ReactComponent as UserTick } from "../../../assets/Icons/Check-filled.svg";
import { useChargeToken } from "../../../hooks/OneTimePayment/useChargeToken";
import PaymentError from "../../UI/PaymentError";

const SavedPMCheckout = ({
  onSavePMDrawerClose,
  savePMSelected,
  paymentError,
}) => {
  const { chargeToken, loading } = useChargeToken();

  const onPayClick = async () => {
    await chargeToken({ savePMSelected, onSavePMDrawerClose });
  };

  return (
    <div className="flex flex-col gap-5 font-sans sm:py-3 sm:px-6 z-[1000]">
      <div className="flex items-center justify-between gap-2 text-xl font-medium">
        Pay with saved details
        <IoCloseOutline
          size={20}
          className="cursor-pointer"
          onClick={onSavePMDrawerClose}
        />
      </div>
      <div className="relative p-3 my-4 border-2 border-blue-500 rounded-lg shadow-md">
        <div className="flex items-center justify-start gap-4">
          {/* Card Brand & User Tick */}
          <div className="flex items-center gap-2">
            <UserTick />
            <div className="h-fit">
              {cardBrand(savePMSelected?.cardDetails?.brand?.toLowerCase())}
            </div>
          </div>

          {/* Card Details */}
          <div className="flex items-center gap-2">
            <span>
              **** {savePMSelected?.cardDetails?.lastFourDigits}
            </span>
            <div className="h-5 mx-2 border-l border-gray-300" />
            {savePMSelected?.cardDetails?.expiryMonth &&
              savePMSelected?.cardDetails?.expiryYear && (
                <span className="text-xs">
                  {savePMSelected?.cardDetails?.expiryMonth} /{" "}
                  {savePMSelected?.cardDetails?.expiryYear}
                </span>
              )}
          </div>
        </div>
      </div>
      {paymentError && <PaymentError paymentError={paymentError} />}
      <Button
        size="large"
        className="font-sans font-semibold"
        block
        htmlType="submit"
        loading={loading}
        onClick={() => onPayClick()}
      >
        Pay
      </Button>
    </div>
  );
};

export default SavedPMCheckout;
