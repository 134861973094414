import React from "react";
import usePrefetchCurrencies from "../../hooks/OneTimePayment/usePrefetchCurrencies";
import { useGetMerchantLogo } from "../../hooks/OneTimePayment/useGetMerchantLogo";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext.jsx";
import Container from "./Container";
import ProductDetails from "./ProductDetails";

const InitilizationContainer = () => {
  const {
    xIntentId,
    setStatus,
    setLoadingMain,
    amount,
    currency,
    amountLoading,
  } = useOneTimePaymentsContext();

  const {
    merchantName,
    currenciesOptionsList,
    billingDetails,
    setBillingDetails,
    getPaymentMethod,
    paymentMethodList,
    productDetails,
  } = usePrefetchCurrencies();

  const { merchantLogo } = useGetMerchantLogo({
    xIntentId,
    setLoadingMain,
    setStatus,
  });

  return (
    <div className="flex sm:h-screen w-full justify-center">
      {productDetails && (
        <ProductDetails
          merchantLogo={merchantLogo}
          productDetails={productDetails}
          amount={amount}
          currency={currency}
          amountLoading={amountLoading}
          merchantName={merchantName}
          currenciesOptionsList={currenciesOptionsList}
        />
      )}
      <Container
        merchantName={merchantName}
        currenciesOptionsList={currenciesOptionsList}
        billingDetails={billingDetails}
        setBillingDetails={setBillingDetails}
        getPaymentMethod={getPaymentMethod}
        paymentMethodList={paymentMethodList}
        merchantLogo={merchantLogo}
      />
    </div>
  );
};

export default InitilizationContainer;
