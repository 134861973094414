import React from "react";
import paypal from "../../../assets/paymentMethods/paypal.png";
import CheckoutFooter from "../../UI/CheckoutFooter";
import useTokyoWebSDKSetup from "../../../hooks/OneTimePayment/useTokyoWebSDKSetup";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";

const PaypalCheckout = () => {
  const {
    intentDetails,
    paymentMethod,
    amount,
    currency,
    secret,
    mode,
    setStatus,
    setPayPalBtnLoading,
    setPaymentError,
  } = useOneTimePaymentsContext();

  useTokyoWebSDKSetup({
    intentDetails,
    paymentMethod,
    amount,
    currency,
    secret,
    mode,
    setStatus,
    setPayPalBtnLoading,
    setPaymentError,
  });

  return (
    <div className="border border-[#e6e6e6] p-3 rounded-lg">
      <img className="mb-3 h-[2em] w-auto" src={paypal} alt="Paypal" />
      <p className="text-[#30313D]">Paypal selected.</p>
      <div className="h-[1px] bg-[#e6e6e6] w-full my-3"></div>
      <CheckoutFooter
        text={
          "Another step will appear to securely submit your payment information."
        }
      />
    </div>
  );
};

export default PaypalCheckout;
